import React, { useState, useEffect, useCallback, useRef } from "react";
import { Container, CssBaseline, Paper, TextField, Typography } from "@mui/material";
import ImageControl from "./ImageSlider";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import { DL_server_url } from "../config/config";
import { Button } from "@mui/base";
import { event } from "jquery";

import "./photoControl.css";

const styles = {
	fullPagePaper: {
		width: "100%", // Set to 100% for full-width
		padding: "100px", // Adjust padding as needed
		marginTop: "5px",
		display: "flex"
	}
};

interface payloadofPpu {
	image: string;
	actual_length: string;
}

interface ImageDataObject {
	Left_Top: string;
	Right_Top: string;
}

import "./photoControl.css";

const PhotoControl: React.FC = () => {
	const [leftPPU, setLeftPPU] = useState<number>(parseInt(localStorage.getItem("leftPPU") || "0"));
	const [rightPPU, setRightPPU] = useState<number>(parseInt(localStorage.getItem("rightPPU") || "0"));
	const [leftOriginal, setLeftOriginal] = useState<string>("");
	const [rightOriginal, setRightOriginal] = useState<string>("");

	const sendImagesToApi = useCallback(
		async (imageDataObject: ImageDataObject) => {
			// Define the API endpoint URL
			const apiUrl = `${DL_server_url}ppu/`; // Replace with your API URL

			try {
				// Remove the data URI prefix from each image
				const payloadOfLeftPpu: payloadofPpu = {
					image: imageDataObject.Left_Top.replace("data:image/png;base64,", ""),
					actual_length: leftOriginal
				};

				const payloadOfRightPpu: payloadofPpu = {
					image: imageDataObject.Right_Top.replace("data:image/png;base64,", ""),
					actual_length: rightOriginal
				};

				console.log("Left Payload :", payloadOfLeftPpu);
				console.log("Right Payload :", payloadOfRightPpu);

				// Send the POST request using Axios with the cleaned image data
				const response_left = await axios.post(apiUrl, payloadOfLeftPpu);
				const response_right = await axios.post(apiUrl, payloadOfRightPpu);
				console.log("Images sent successfully!", response_left.data, response_right.data);
				//const data = response.data;
				setLeftPPU(response_left.data.ppu);
				setRightPPU(response_right.data.ppu);

				localStorage.setItem("leftPPU", response_left.data.ppu);
				localStorage.setItem("rightPPU", response_right.data.ppu);
			} catch (error) {
				console.log("Error sending images:", error);
				// Handle errors here
			}
		},
		[leftOriginal, rightOriginal, setLeftPPU, setRightPPU, leftPPU, rightPPU, leftPPU, rightPPU]
	);

	const captureImages = useCallback(async () => {
		// Define the webcam IDs based on local storage values
		const leftTopDeviceId = localStorage.getItem("leftTop") || "";
		const rightTopDeviceId = localStorage.getItem("rightTop") || "";

		const getHorizentalValue = (direction: string): number[] => {
			if (direction === "Left_Top") {
				const width = JSON.parse(localStorage.getItem("leftTopHorizentalValue") || "[0,100]");
				return width;
			} else if (direction === "Right_Top") {
				const width = JSON.parse(localStorage.getItem("rightTopHorizentalValue") || "[0,100]");
				return width;
			} else if (direction === "Left_Side") {
				const width = JSON.parse(localStorage.getItem("leftSideHorizentalValue") || "[0,100]");
				return width;
			}
			const width = JSON.parse(localStorage.getItem("rightSideHorizentalValue") || "[0,100]");
			return width;
		};

		const getVericalValue = (direction: string): number[] => {
			if (direction === "Left_Top") {
				const height = JSON.parse(localStorage.getItem("leftTopVerticalValue") || "[0,100]");
				return height;
			} else if (direction === "Right_Top") {
				const height = JSON.parse(localStorage.getItem("rightTopVerticalValue") || "[0,100]");
				return height;
			} else if (direction === "Left_Side") {
				const height = JSON.parse(localStorage.getItem("leftSideVerticalValue") || "[0,100]");
				return height;
			}
			const height = JSON.parse(localStorage.getItem("rightSideVerticalValue") || "[0,100]");
			return height;
		};

		// Create an object to store captured images
		const capturedImagesObject: ImageDataObject = {
			Left_Top: "",
			Right_Top: ""
		};

		// Create an array of promises to capture images from webcams
		const promises = [captureImage(leftTopDeviceId, "Left_Top"), captureImage(rightTopDeviceId, "Right_Top")];

		// Wait for all promises to resolve
		await Promise.all(promises);

		// Send the capturedImagesObject to the API
		await sendImagesToApi(capturedImagesObject);

		// Function to capture an image from a webcam and update the state
		async function captureImage(deviceId: string, propertyName: keyof ImageDataObject) {
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");

			if (ctx) {
				const video = document.createElement("video");
				video.autoplay = true;
				video.playsInline = true;

				try {
					// Set the selected device as the video source
					const stream = await navigator.mediaDevices.getUserMedia({
						video: {
							deviceId,
							width: 640,
							height: 480
						}
					});
					video.srcObject = stream;

					// Wait for the video metadata to load
					await new Promise<void>((resolve: any) => {
						video.onloadedmetadata = () => {
							// canvas.width = 640 * 3;
							// canvas.height = 480 * 3;
							canvas.width = video.videoWidth;
							canvas.height = video.videoHeight;
							ctx.drawImage(
								video,
								getHorizentalValue(propertyName)[0],
								getVericalValue(propertyName)[0],
								canvas.width -
									(getHorizentalValue(propertyName)[0] + (100 - getHorizentalValue(propertyName)[1])),
								canvas.height -
									(getVericalValue(propertyName)[0] + (100 - getVericalValue(propertyName)[1]))
							);

							// Convert the captured image to a base64 string
							const base64Image = canvas.toDataURL("image/png");

							// Update the corresponding property in the capturedImagesObject
							capturedImagesObject[propertyName] = base64Image;

							// Stop the webcam stream
							stream.getTracks().forEach((track: any) => track.stop());

							resolve();
						};
					});
				} catch (error) {
					console.error(`Error accessing webcam ${deviceId}:`, error);
				}
			}
		}
	}, [leftOriginal, setLeftOriginal, rightOriginal, setRightOriginal]);

	// Initialize state and retrieve values from local storage if available
	const [leftAdjustment, setLeftAdjustment] = useState<number>(
		parseInt(localStorage.getItem("leftAdjustment") || "0")
	);
	const [rightAdjustment, setRightAdjustment] = useState<number>(
		parseInt(localStorage.getItem("rightAdjustment") || "0")
	);

	const leftPpuTextFieldRef = useRef<any>(null);
	const rightPpuTextFieldRef = useRef<any>(null);

	const handleOriginalDirection = useCallback(
		(_direction: "LEFT" | "RIGHT", event: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = event.target;
			console.log("valu of the input : ", typeof value, value);
			// const parsedValue = parseFloat(value);
			if (_direction === "LEFT") setLeftOriginal(value);
			else if (_direction === "RIGHT") setRightOriginal(value);
			console.log("LO : " + leftOriginal + " RO :" + rightOriginal); /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		},
		[setLeftOriginal, setRightOriginal, leftOriginal, rightOriginal]
	);

	// Update local storage when slider values change
	useEffect(() => {
		localStorage.setItem("leftAdjustment", leftAdjustment.toString());
	}, [leftAdjustment]);

	useEffect(() => {
		localStorage.setItem("rightAdjustment", rightAdjustment.toString());
	}, [rightAdjustment]);

	return (
		<Container maxWidth="xl">
			<CssBaseline />
			<Typography
				variant="h2"
				component="div"
				fontFamily="Quicksand, sans-serif"
				fontWeight="light"
				marginRight="50px"
				marginBottom="50px"
				marginTop="50px"
			>
				ADJUSTMENT
			</Typography>
			<Paper elevation={3} style={styles.fullPagePaper}>
				<div style={{ flex: 1 }}>
					<div>
						<ImageControl
							label="Left Adjustment"
							value={leftAdjustment}
							onChange={(newValue: any) => setLeftAdjustment(newValue)}
						/>
					</div>
					<div>
						<ImageControl
							label="Right Adjustment"
							value={rightAdjustment}
							onChange={(newValue: any) => setRightAdjustment(newValue)}
						/>
					</div>
					<div className="PPU-container">
						<div className="input-field-container">
							<h2>Pixel Per Unit (PPU)</h2>
							<TextField
								id="outlined-basic"
								label="Left PPU"
								variant="outlined"
								color="success"
								value={leftOriginal}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									handleOriginalDirection("LEFT", event)
								}
								// inputRef={leftPpuTextFieldRef}
								sx={{ marginRight: "2rem" }}
							/>
							<TextField
								id="outlined-basic"
								label="Right PPU"
								color="success"
								variant="outlined"
								value={rightOriginal}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									handleOriginalDirection("RIGHT", event)
								}
								// inputRef={rightPpuTextFieldRef}
								sx={{ marginRight: "2rem" }}
							/>
							<Button className="capture-image-btn" onClick={captureImages}>
								<Typography
									variant="h5"
									component="div"
									fontFamily="Quicksand, sans-serif"
									fontWeight="light"
								>
									Submit
								</Typography>
							</Button>
						</div>

						<div className="resultant-container">
							{leftPPU != 0 && (
								<div>
									<h2>Left PPU</h2>
									<Typography>{leftPPU}</Typography>
								</div>
							)}
							{rightPPU != 0 && (
								<div>
									<h2>Right PPU</h2>
									<Typography>{rightPPU}</Typography>
								</div>
							)}
						</div>
					</div>
				</div>
			</Paper>
		</Container>
	);
};

export default PhotoControl;
