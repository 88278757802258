import { FC, useState, useContext } from "react";
import "./appBar.css";
import { GroupLogo, Logo } from "../../../assets";

import { IoMdSettings } from "react-icons/io";
import { Button, IconButton } from "@mui/material";
import { AiFillHome, AiOutlineHome, AiOutlineLogout } from "react-icons/ai";
import Settings from "../../Settings";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext/AuthContext";
import { Console } from "console";

const AppBar: FC = (): JSX.Element => {
	const { isLoggedIn, setLogIn } = useContext(AuthContext);
	const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
	const navigate = useNavigate();

	const handleLogoutOnClick = () => {
		setLogIn(false);
		localStorage.removeItem("store_number");
		localStorage.removeItem("group_number");
		localStorage.removeItem("store_name");
		navigate("/login");
	};

	const handleOpenSettingsDialog = () => {
		setOpenSettingsDialog(true);
	};

	const handleCloseSettingsDialog = () => {
		setOpenSettingsDialog(false);
	};

	const handleClick = () => {
		console.log("clicked");
		const storeNumber = localStorage.getItem("store_number");
		const groupNumber = localStorage.getItem("group_number");
		console.log("storeNumber", storeNumber);
		console.log("groupNumber", groupNumber);

		if (storeNumber && groupNumber) {
			navigate("/landing");
		} else {
			setLogIn(false);
		}
	};

	return (
		<div className="app-bar-wrap">
			<div className="app-bar">
				<div className="group-logo">
					<img src={GroupLogo} />
				</div>
				<div className="nav-links">
					<Button onClick={handleClick}>
						<span className="mgr-10">Home</span>
						<span>
							<AiFillHome className="icon" />
						</span>
					</Button>
					<Button onClick={handleOpenSettingsDialog}>
						<span className="mgr-10">Settings</span>
						<span>
							<IoMdSettings className="icon" />
						</span>
					</Button>
					<Button onClick={handleLogoutOnClick}>
						<span className="mgr-10">Logout</span>
						<span>
							<AiOutlineLogout className="icon" />
						</span>
					</Button>
					<img src={Logo} />
				</div>
				<Settings
					openSettingsDialog={openSettingsDialog}
					handleCloseSettingsDialog={handleCloseSettingsDialog}
				/>
			</div>
		</div>
	);
};

export default AppBar;
