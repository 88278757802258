import React, { useState, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

interface MediaDeviceInfo {
	deviceId: string;
	kind: string;
	label: string;
}

interface WebcamCardProps {
	selectedDeviceId: string;
	videoWidth?: number;
	videoHeight?: number;
	verticalValue?: number[];
	horizontalValue?: number[];
}

const WebcamCard: React.FC<WebcamCardProps> = ({
	selectedDeviceId,
	videoWidth,
	videoHeight,
	verticalValue = [0, 100],
	horizontalValue = [0, 100]
}) => {
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const [stream, setStream] = useState<MediaStream | null>(null);

	useEffect(() => {
		const getSelectedDevice = async () => {
			try {
				if (!selectedDeviceId) {
					// If selectedDeviceId is empty, render a blank image (or your <img> tag)
					return;
				}

				const devices: MediaDeviceInfo[] = await navigator.mediaDevices.enumerateDevices();
				const webcamDevice = devices.find(
					(device: any) => device.deviceId === selectedDeviceId && device.kind === "videoinput"
				);

				if (webcamDevice) {
					const constraints: MediaStreamConstraints = {
						video: {
							deviceId: webcamDevice.deviceId,
							width: 640,
							height: 480
						}
					};
					const newStream = await navigator.mediaDevices.getUserMedia(constraints);
					setStream(newStream);

					if (videoRef.current) {
						videoRef.current.srcObject = newStream;
					}
				}
			} catch (error) {
				console.error("Error accessing selected webcam:", error);
			}
		};

		getSelectedDevice();
	}, [selectedDeviceId]);

	return (
		<Card style={{ boxShadow: "none", background: "transparent", padding: 0, paddingBottom: 0 }}>
			{/* <div
				style={{
					position: "sticky",
					top: 0,
					left: 0,
					width: "100%",
					height: "`${(100 - 20) * 6}px`", // Adjust height as needed
					backgroundColor: "red",
					opacity: 0.5 // Adjust opacity as needed
				}}
			></div> */}

			<CardContent sx={{ padding: 0, margin: 0, paddingBottom: 0 }} style={{ paddingBottom: 0 }}>
				{selectedDeviceId && stream ? (
					<video
						ref={videoRef}
						autoPlay
						playsInline
						style={{
							width: videoWidth ? `${videoWidth}px` : "100%",
							height: videoHeight ? `${videoHeight}px` : "auto",
							maxWidth: "100%",
							clipPath: `inset(${100 - verticalValue[1]}% ${100 - horizontalValue[1]}% ${
								verticalValue[0]
							}% ${horizontalValue[0]}%)`
							// clipPath: `inset(${verticalValue[0]}% $15% `
							// clipPath: "circle(50% at 50% 70%)"
							// marginTop: "0.5rem"
						}}
					/>
				) : (
					<img
						src="blank.png"
						alt="Blank"
						style={{
							width: videoWidth ? `${videoWidth}px` : "100%",
							height: videoHeight ? `${videoHeight}px` : "auto",
							maxWidth: "100%",
							marginTop: "0.5rem"
						}}
					/>
				)}
			</CardContent>
		</Card>
	);
};

export default WebcamCard;
