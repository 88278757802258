import { useState, useEffect } from "react";
import axios, { AxiosResponse } from "axios";
import { DL_server_url } from "../config/config";
import { AnalysisFlowPropType } from "../ts/propTypes/FootcareAnalysisProps.types";
import { trackPromise } from "react-promise-tracker";
import { NextArrow } from "../assets";
import { useNavigate } from "react-router-dom";

interface ImageDataObject {
	Left_Side: string;
	Right_Side: string;
	Left_Top: string;
	Right_Top: string;
}

interface payloadOfFootStorApi extends ImageDataObject {
	name: string;
	Sex: string;
	min_age: string;
	max_age: string;
	left_adjustment: number;
	right_adjustment: number;
	leftPPU: number;
	rightPPU: number;
	group_number: number;
	store_number: number;
	group_name: string;
	store_name: string;
	dev_id: string;
	weight: number;
	right_top_horizontal_value: string;
	right_top_vertical_value: string;
	right_side_horizontal_value: string;
	right_side_vertical_value: string;
	left_top_horizontal_value: string;
	left_top_vertical_value: string;
	left_side_horizontal_value: string;
	left_side_vertical_value: string;
}

function ImageCaptureButton({ footcareAnalysis, setFootcareAnalysis, setCurrentIdx }: AnalysisFlowPropType) {
	// Retrieve device IDs from local storage
	const leftTopDeviceId = localStorage.getItem("leftTop") || "";
	const rightTopDeviceId = localStorage.getItem("rightTop") || "";
	const leftSideDeviceId = localStorage.getItem("leftSide") || "";
	const rightSideDeviceId = localStorage.getItem("rightSide") || "";

	const [hasError, setHasError] = useState<boolean>(false);

	// State to store captured base64 images
	const navigate_2 = useNavigate();
	const [capturedImages, setCapturedImages] = useState<ImageDataObject>({
		Left_Side: "",
		Right_Side: "",
		Left_Top: "",
		Right_Top: ""
	});

	useEffect(() => {
		// Initialize the captured images object when device IDs change
		setCapturedImages({
			Left_Side: "",
			Right_Side: "",
			Left_Top: "",
			Right_Top: ""
		});
	}, [leftTopDeviceId, rightTopDeviceId, leftSideDeviceId, rightSideDeviceId]);

	useEffect(() => {
		if (localStorage.getItem("showCamera") === "false") {
			const timeId = setTimeout(async () => {
				await captureImages(navigate_2);
			}, 2000);
			return () => {
				clearTimeout(timeId);
			};
		}
	}, []);

	const sendImagesToApi = async (imageDataObject: ImageDataObject, navigate: any) => {
		// Define the API endpoint URL
		const apiUrl = `${DL_server_url}scan/`;

		try {
			const payloadOfFootStorApi: payloadOfFootStorApi = {
				Left_Side: imageDataObject.Left_Side.replace("data:image/png;base64,", ""),
				Right_Side: imageDataObject.Right_Side.replace("data:image/png;base64,", ""),
				Left_Top: imageDataObject.Left_Top.replace("data:image/png;base64,", ""),
				Right_Top: imageDataObject.Right_Top.replace("data:image/png;base64,", ""),
				name: footcareAnalysis.name,
				Sex: footcareAnalysis.gender,
				min_age: footcareAnalysis.minimum_age,
				max_age: footcareAnalysis.maximum_age,
				left_adjustment: Number(localStorage.getItem("leftAdjustment") || "1"),
				right_adjustment: Number(localStorage.getItem("rightAdjustment") || "1"),
				leftPPU: Number(localStorage.getItem("leftPPU") || "1"),
				rightPPU: Number(localStorage.getItem("rightPPU") || "1"),
				group_number: Number(localStorage.getItem("group_number") || "989"),
				group_name: localStorage.getItem("group_name") || "",
				store_number: Number(localStorage.getItem("store_number") || "898"),
				store_name: localStorage.getItem("store_name") || "",
				dev_id: localStorage.getItem("machine_id") || "",
				weight: footcareAnalysis.weight,
				right_top_horizontal_value: String(localStorage.getItem("rightTopHorizentalValue")) || "[0,0]",
				right_top_vertical_value: String(localStorage.getItem("rightTopVerticalValue")) || "[0,0]",
				right_side_horizontal_value: String(localStorage.getItem("rightSideHorizentalValue")) || "[0,0]",
				right_side_vertical_value: String(localStorage.getItem("rightSideVerticalValue")) || "[0,0]",
				left_top_horizontal_value: String(localStorage.getItem("leftTopHorizentalValue")) || "[0,0]",
				left_top_vertical_value: String(localStorage.getItem("leftTopVerticalValue")) || "[0,0]",
				left_side_horizontal_value: String(localStorage.getItem("leftSideHorizentalValue")) || "[0,0]",
				left_side_vertical_value: String(localStorage.getItem("leftSideVerticalValue")) || "[0,0]"
			};

			// Send the POST request using Axios with the cleaned image data
			const response: AxiosResponse = await trackPromise(axios.post(apiUrl, payloadOfFootStorApi));

			const { data, status } = response;
			console.log("Images sent successfully!", data);
			// const data = response.data;
			if ((!data.Left_Length && !data.Right_Length) || status != 200) {
				console.log("Error sending images to server:", status);

				console.log("hi");
				return;
			}
			setFootcareAnalysis((prevFootcareAnalysis: any) => {
				return Object.assign({}, prevFootcareAnalysis, {
					left_length: data["Left_Length"],
					left_width: data["Left_Width"],
					left_instep_height: data["Left_Instep_Height"],
					left_arch_height: data["Left_Arch_Height"],
					right_length: data["Right_Length"],
					right_width: data["Right_Width"],
					right_instep_height: data["Right_Instep_Height"],
					right_arch_height: data["Right_Arch_Height"],
					left_shoe_size: data["Left_shoe_size_UK"],
					right_shoe_size: data["Right_shoe_size_UK"],
					left_arch_type: data["Arch_Type_Left"],
					right_arch_type: data["Arch_Type_Right"],
					left_shoe_size_eu: data["Left_shoe_size_EU"],
					right_shoe_size_eu: data["Right_shoe_size_EU"],
					right_width_type: data["Width_Type_Right"],
					left_width_type: data["Width_Type_Left"],
					scan_id: data["scan_id"],
					scan_object_id: data["scan_object_id"],
					ppu_left: data["ppu_left"],
					ppu_right: data["ppu_right"],
					adj_right: data["adj_right"],
					adj_left: data["adj_left"],
					left_heel_width: data["Left_Heel_Width"],
					right_heel_width: data["Right_Heel_Width"],
					marker_distance_left: data["Marker_Distance_Left"],
					marker_distance_right: data["Marker_Distance_Right"]
				});
			});
			setCurrentIdx((prevCurrentIdx: number) => prevCurrentIdx + 1);
			// You can handle the API response here
		} catch (error) {
			console.log("Error sending images:", error);
			navigate("/error");
			// Handle errors here
		}
	};

	// Function to capture images from webcams and update the capturedImages state
	const captureImages = async (navigate: any) => {
		// Define the webcam IDs based on local storage values
		const leftTopDeviceId = localStorage.getItem("leftTop") || "";
		const rightTopDeviceId = localStorage.getItem("rightTop") || "";
		const leftSideDeviceId = localStorage.getItem("leftSide") || "";
		const rightSideDeviceId = localStorage.getItem("rightSide") || "";

		// Create an object to store captured images
		const capturedImagesObject: ImageDataObject = {
			Left_Side: "",
			Right_Side: "",
			Left_Top: "",
			Right_Top: ""
		};

		// Create an array of promises to capture images from webcams
		const promises = [
			captureImage(leftTopDeviceId, "Left_Top"),
			captureImage(rightTopDeviceId, "Right_Top"),
			captureImage(leftSideDeviceId, "Left_Side"),
			captureImage(rightSideDeviceId, "Right_Side")
		];

		// Wait for all promises to resolve
		await Promise.all(promises);

		// Send the capturedImagesObject to the API
		sendImagesToApi(capturedImagesObject, navigate_2);

		// Function to capture an image from a webcam and update the state
		async function captureImage(deviceId: string, propertyName: keyof ImageDataObject) {
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");

			if (ctx) {
				const video = document.createElement("video");
				video.autoplay = true;
				video.playsInline = true;

				try {
					const stream = await navigator.mediaDevices.getUserMedia({
						video: {
							deviceId,
							width: 640,
							height: 480
						}
					});

					video.srcObject = stream;

					// Wait for the video metadata to load
					await new Promise<void>((resolve: any) => {
						video.onloadedmetadata = () => {
							canvas.width = video.videoWidth;
							canvas.height = video.videoHeight;
							const cropLeft = 0;
							const cropTop = 0;
							const cropWidth = canvas.width;
							const cropHeight = canvas.height;

							ctx.drawImage(video, cropLeft, cropTop, cropWidth, cropHeight);
							const base64Image = canvas.toDataURL("image/png");
							capturedImagesObject[propertyName] = base64Image;
							stream.getTracks().forEach((track: any) => track.stop());
							resolve();
						};
					});
				} catch (error) {
					console.error(`Error accessing webcam ${deviceId}:`, error);
				}
			}
		}
	};

	return (
		<div className="bottom-pointer-right" onClick={captureImages}>
			<img src={NextArrow} />
			{/* <GrNext className="icon-1" />
					<GrNext className="icon-2" /> */}
		</div>
	);
}

export default ImageCaptureButton;
