import React, { FC, useCallback, useEffect, useState } from "react";
import { AnalysisFlowPropType } from "../../../../ts/propTypes/FootcareAnalysisProps.types";
import { D1_PHQs, D25_PHQs, D3_PHQs, D4_PHQs, D69_PHQs, D7_PHQs, D8_PHQs } from "../../../../constants/PHQ";
import { FaHandPointRight } from "react-icons/fa";
import ThemeRadioButton from "../../../../components/shared/radioButton/ThemeRadioButton";
import { Button } from "@mui/base";
import { type } from "os";

import "./PHQCore.css";

const PHQCore: FC<AnalysisFlowPropType & { foot: "RIGHT" | "LEFT" }> = ({
	footcareAnalysis,
	setFootcareAnalysis,
	foot
}: AnalysisFlowPropType & { foot: "LEFT" | "RIGHT" }): JSX.Element => {
	const [leftFootCorePHQs, setLeftFootCorePHQs] = useState<any[]>([]);
	const [rightFootCorePHQs, setRightFootCorePHQs] = useState<any[]>([]);
	const [corePHQs, setCorePHQs] = useState<any[]>([]);

	const handlePHQChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>, success: boolean) => {
			const { name, value } = event.target;
			setFootcareAnalysis((prevFootcareAnalysis: any) => {
				prevFootcareAnalysis[foot][name] = success;
				return Object.assign({}, prevFootcareAnalysis);
			});
		},
		[foot, setFootcareAnalysis]
	);

	const generateCorePHQs = useCallback(() => {
		if (foot === "LEFT") {
			const _leftFootCorePHQs: any[] = [];
			if (footcareAnalysis.left_pain_point_1) {
				_leftFootCorePHQs.push(...D1_PHQs);
			}
			if (footcareAnalysis.left_pain_point_2 || footcareAnalysis.left_pain_point_5) {
				_leftFootCorePHQs.push(...D25_PHQs);
			}
			if (footcareAnalysis.left_pain_point_3) {
				_leftFootCorePHQs.push(...D3_PHQs);
			}
			if (footcareAnalysis.left_pain_point_4) {
				_leftFootCorePHQs.push(...D4_PHQs);
			}
			if (footcareAnalysis.left_pain_point_6 || footcareAnalysis.left_pain_point_5) {
				_leftFootCorePHQs.push(...D69_PHQs);
			}
			if (footcareAnalysis.left_pain_point_7) {
				_leftFootCorePHQs.push(...D7_PHQs);
			}
			if (footcareAnalysis.left_pain_point_8) {
				_leftFootCorePHQs.push(...D8_PHQs);
			}
			setLeftFootCorePHQs(Object.assign([], _leftFootCorePHQs));
			setCorePHQs(Object.assign([], _leftFootCorePHQs));
		} else {
			const _rightFootCorePHQs: any[] = [];
			if (footcareAnalysis.right_pain_point_1) {
				_rightFootCorePHQs.push(...D1_PHQs);
			}
			if (footcareAnalysis.right_pain_point_2 || footcareAnalysis.right_pain_point_5) {
				_rightFootCorePHQs.push(...D25_PHQs);
			}
			if (footcareAnalysis.right_pain_point_3) {
				_rightFootCorePHQs.push(...D3_PHQs);
			}
			if (footcareAnalysis.right_pain_point_4) {
				_rightFootCorePHQs.push(...D4_PHQs);
			}
			if (footcareAnalysis.right_pain_point_6) {
				_rightFootCorePHQs.push(...D69_PHQs);
			}
			if (footcareAnalysis.right_pain_point_7) {
				_rightFootCorePHQs.push(...D7_PHQs);
			}
			if (footcareAnalysis.right_pain_point_8) {
				_rightFootCorePHQs.push(...D8_PHQs);
			}
			setRightFootCorePHQs(Object.assign([], _rightFootCorePHQs));
			setCorePHQs(Object.assign([], _rightFootCorePHQs));
		}
	}, [
		foot,
		footcareAnalysis.left_pain_point_1,
		footcareAnalysis.left_pain_point_2,
		footcareAnalysis.left_pain_point_3,
		footcareAnalysis.left_pain_point_4,
		footcareAnalysis.left_pain_point_5,
		footcareAnalysis.left_pain_point_6,
		footcareAnalysis.left_pain_point_7,
		footcareAnalysis.left_pain_point_8,
		footcareAnalysis.right_pain_point_1,
		footcareAnalysis.right_pain_point_2,
		footcareAnalysis.right_pain_point_3,
		footcareAnalysis.right_pain_point_4,
		footcareAnalysis.right_pain_point_5,
		footcareAnalysis.right_pain_point_6,
		footcareAnalysis.right_pain_point_7,
		footcareAnalysis.right_pain_point_8
	]);

	const handleDiagnosisClick = useCallback(() => {
		const {
			general_Q7,
			general_Q8,
			general_Q9,
			general_Q10,
			general_Q11,
			general_Q12,
			general_Q13,
			general_Q14,
			general_Q15,
			general_Q16,
			general_Q17,
			general_Q18,
			general_Q19,
			general_Q20,
			general_Q21,
			general_Q22,
			general_Q23,
			general_Q24,
			general_Q25,
			general_Q26,
			general_Q27,
			general_Q28,
			general_Q29,
			general_Q30,
			general_Q31,
			general_Q32,
			general_Q33,
			general_Q34,
			general_Q35,
			general_Q36,
			general_Q37
		} = footcareAnalysis[foot];
		let diagnosis = "";

		if ([general_Q7, general_Q8, general_Q9].includes(true)) {
			diagnosis = diagnosis + "Metatarsalgia/ Morton’s Neuroma + ";
		}
		if ([general_Q10, general_Q11, general_Q12, general_Q13, general_Q14].includes(true)) {
			diagnosis = diagnosis + "Tibial post tendinitis + ";
		}
		if ([general_Q15, general_Q16].includes(true)) {
			diagnosis = diagnosis + "Planter Fasciitis + ";
		}
		if ([general_Q17, general_Q18, general_Q19].includes(true)) {
			diagnosis = diagnosis + "Achilles Tendinitis + ";
		}
		if ([general_Q20, general_Q21, general_Q22, general_Q23, general_Q24].includes(true)) {
			diagnosis = diagnosis + "Hallux rigidus + ";
		}
		if (
			[general_Q25, general_Q26, general_Q27, general_Q28, general_Q29, general_Q30, general_Q31].includes(true)
		) {
			diagnosis = diagnosis + "Ankle Sprain + ";
		}
		if ([general_Q32, general_Q33, general_Q34, general_Q35, general_Q36, general_Q37].includes(true)) {
			diagnosis = diagnosis + "Midfoot Arthiritis";
		}
		alert(diagnosis);
	}, [foot, footcareAnalysis]);

	useEffect(() => {
		generateCorePHQs();
	}, [generateCorePHQs]);

	console.log("DATA_REQ", footcareAnalysis);
	return (
		<div className="PHQ-core theme-cont">
			<div className="theme-sub-cont">
				<div
					className="left-item"
					style={{
						alignItems: "flex-start",
						flex: 2,
						height: "70vh",
						overflowY: "scroll",
						overflowX: "hidden"
					}}
				>
					{corePHQs.map((corePHQ, idx) => {
						return (
							<div className="question-container" key={idx}>
								<div className="question" style={{ fontSize: "2rem" }}>
									<span>
										<FaHandPointRight className="question-icon" />
									</span>
									&nbsp;&nbsp;&nbsp;{corePHQ.label}
								</div>
								<div className="radio-container">
									<ThemeRadioButton
										checked={
											footcareAnalysis[foot][corePHQ.name] === null
												? false
												: footcareAnalysis[foot][corePHQ.name]
										}
										success
										handleChange={handlePHQChange}
										label="Yes"
										name={corePHQ.name}
										fontSize="2rem"
									/>
									<ThemeRadioButton
										checked={
											footcareAnalysis[foot][corePHQ.name] === null
												? false
												: !footcareAnalysis[foot][corePHQ.name]
										}
										success={false}
										handleChange={handlePHQChange}
										label="No"
										name={corePHQ.name}
										fontSize="2rem"
									/>
								</div>
							</div>
						);
					})}
				</div>
				<div className="right-item PHQ-core-back-img" />
				{/* <Button onClick={handleDiagnosisClick}>Generate</Button> */}
			</div>
		</div>
	);
};

export default PHQCore;
