import { FC, useCallback, useState } from "react";

import "./indentifyingInformation.css";
import { AnalysisFlowPropType } from "../../../ts/propTypes/FootcareAnalysisProps.types";
import CustomInput from "../../../components/shared/customInput/CustomInput";
import Button from "@mui/material-next/Button";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import { DL_server_url } from "../../../config/config";
import { NextArrow } from "../../../assets";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
//import Modal from "@mui/material/Modal";

const IndentifyingInformation: FC<AnalysisFlowPropType> = ({
	footcareAnalysis,
	setFootcareAnalysis,
	setCurrentIdx
}: AnalysisFlowPropType): JSX.Element => {
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		bgcolor: "background.paper",
		border: "2px solid #000",
		boxShadow: 24,
		p: 4
	};
	const apiUrl = `${DL_server_url}report/`;
	// const handleOpen = async () => {
	// 	// "https://96e0-122-176-164-234.ngrok-free.app/names_for_phone_number"
	// 	const userWithSamePhone = await axios.get(`${DL_server_url}names_for_phone_number`, {
	// 		/*headers: {
	// 			"ngrok-skip-browser-warning": "0"
	// 		},*/
	// 		params: {
	// 			phone: "7595874332"
	// 		}
	// 	});
	// 	console.log(userWithSamePhone);
	// 	setModalDisplay(true);
	// };
	// const handleClose = () => {
	// 	setModalDisplay(false);
	// 	setCurrentIdx((prevCurrentIdx: number) => prevCurrentIdx + 1);
	// };
	// const [modalDisplay, setModalDisplay] = useState(false);
	const sendDataToApi = async () => {
		const payload = {
			scan_id: footcareAnalysis.scan_id,
			name: footcareAnalysis.name,
			contact_number: footcareAnalysis.phone_number,
			sex: footcareAnalysis.gender,
			min_age: footcareAnalysis.minimum_age,
			max_age: footcareAnalysis.maximum_age,
			group_number: localStorage.getItem("group_number"),
			store_number: localStorage.getItem("store_number")
		};
		try {
			const response = await trackPromise(axios.post(apiUrl, payload));
			console.log("data sent successfully!", response.data);
			const data = response.data;

			if (data["link"]) {
				setFootcareAnalysis((prevFootcareAnalysis: any) => {
					return Object.assign({}, prevFootcareAnalysis, {
						link: data["link"]
					});
				});
			}

			console.log("link", data["link"]);
		} catch (error) {
			console.error(error);
		}
		setCurrentIdx((prevCurrentIdx: number) => prevCurrentIdx + 1);
		// handleOpen();
	};

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = event.target;
			setFootcareAnalysis((prevFootcareAnalysis: any) => {
				return Object.assign({}, prevFootcareAnalysis, {
					[name]: value
				});
			});
		},
		[setFootcareAnalysis]
	);

	return (
		<>
			<div className="identifying-information theme-cont" style={{ height: "auto" }}>
				<div className="identifying-back-img" />
				<div className="text-center gradient-header" style={{ fontSize: "5rem" }}>
					Your Basic Foot Analysis is Succesfully Completed
				</div>
				<div className="theme-sub-cont">
					<div className="form-container left-item">
						<div className="mgb-3rem" />
						<h1 className="sub-header text-center">Enter Your Name</h1>
						<div className="mgb-3rem" />
						<CustomInput
							placeholder="Name"
							style={{
								width: 500
							}}
							name="name"
							onChange={handleChange}
						/>
					</div>
					<div className="form-container right-item">
						<div className="mgb-3rem" />
						<h1 className="sub-header text-center">Enter Your Phone Number</h1>
						<div className="mgb-3rem" />
						<CustomInput
							placeholder="Phone Number"
							style={{
								width: 500
							}}
							name="phone_number"
							onChange={handleChange}
						/>
					</div>
				</div>
				<div className="text-center" style={{ marginTop: "10rem" }}>
					<Button
						sx={{
							textAlign: "center",
							fontSize: "2.6rem",
							fontFamily: "inherit",
							fontWeight: "bold",
							paddingLeft: "2rem",
							paddingRight: "2rem",
							background: "#60cf63",
							color: "#1f2149"
						}}
						disabled={false}
						size="large"
						onClick={sendDataToApi}
					>
						Generate Report
					</Button>
				</div>
			</div>
			<div className="bottom-pointer-right" onClick={sendDataToApi}>
				<img src={NextArrow} />
				{/* <GrNext className="icon-1" />
					<GrNext className="icon-2" /> */}
			</div>

			{/* <div>
				<Modal
					open={modalDisplay}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							Text in a modal
						</Typography>
						<Typography id="modal-modal-description" sx={{ mt: 2 }}>
							Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
						</Typography>
					</Box>
				</Modal>
			</div> */}
		</>
	);
};

export default IndentifyingInformation;
