import { FC, useCallback } from "react";
import PainPoint from "../painPoint/PainPoint";
import { AnalysisFlowPropType } from "../../../../ts/propTypes/FootcareAnalysisProps.types";

import "./leftPainPoint.css";

const LeftPainPoint: FC<AnalysisFlowPropType> = ({
	footcareAnalysis,
	setFootcareAnalysis,
	setCurrentIdx
}: AnalysisFlowPropType): JSX.Element => {
	const handlePainPointChange = useCallback(
		(paintPoint: string) => {
			for (let i = 1; i < 9; i++) {
				if (`left_pain_point_${i}` !== paintPoint) {
					setFootcareAnalysis((prevFootcareAnalysis: any) => {
						return Object.assign({}, prevFootcareAnalysis, {
							[`left_pain_point_${i}`]: false
						});
					});
				} else {
					setFootcareAnalysis((prevFootcareAnalysis: any) => {
						return Object.assign({}, prevFootcareAnalysis, {
							[paintPoint]: !prevFootcareAnalysis[paintPoint]
						});
					});
				}
			}
		},
		[setFootcareAnalysis]
	);
	return (
		<div className="left-pain-point theme-cont">
			<div className="left-pain-back-img" />
			<div className="text-center gradient-header header" style={{ fontSize: "5rem" }}>
				Choose Your Pain Point: LEFT FOOT
			</div>
			<div className="theme-sub-cont">
				<div className="left-item">
					<PainPoint
						type={"LEFT"}
						setCurrentIdx={setCurrentIdx}
						footcareAnalysis={footcareAnalysis}
						setFootcareAnalysis={setFootcareAnalysis}
						handlePainPointChange={handlePainPointChange}
					/>
				</div>
				<div className="right-item">
					<div className="left-pain-point-background" />
					<div
						className={
							(!footcareAnalysis.left_pain_point_4 ? "pain-point-item" : "pain-point-item-selected") +
							" left-pain-point-4"
						}
						onClick={() => handlePainPointChange("left_pain_point_4")}
					>
						4
					</div>
					<div
						className={
							(!footcareAnalysis.left_pain_point_7 ? "pain-point-item" : "pain-point-item-selected") +
							" left-pain-point-7"
						}
						onClick={() => handlePainPointChange("left_pain_point_7")}
					>
						7
					</div>
					<div
						className={
							(!footcareAnalysis.left_pain_point_5 ? "pain-point-item" : "pain-point-item-selected") +
							" left-pain-point-5"
						}
						onClick={() => handlePainPointChange("left_pain_point_5")}
					>
						5
					</div>
					<div
						className={
							(!footcareAnalysis.left_pain_point_6 ? "pain-point-item" : "pain-point-item-selected") +
							" left-pain-point-6"
						}
						onClick={() => handlePainPointChange("left_pain_point_6")}
					>
						6
					</div>
					<div
						className={
							(!footcareAnalysis.left_pain_point_8 ? "pain-point-item" : "pain-point-item-selected") +
							" left-pain-point-8"
						}
						onClick={() => handlePainPointChange("left_pain_point_8")}
					>
						8
					</div>
				</div>
			</div>
		</div>
	);
};

export default LeftPainPoint;
