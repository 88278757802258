import { FC, useCallback } from "react";
import { AnalysisFlowPropType } from "../../../../ts/propTypes/FootcareAnalysisProps.types";
import { PHQ } from "../../../../constants/PHQ";

import { FaHandPointRight } from "react-icons/fa";
import ThemeRadioButton from "../../../../components/shared/radioButton/ThemeRadioButton";

import "./PHQIntro.css";

const PHQIntro: FC<AnalysisFlowPropType> = ({
	footcareAnalysis,
	setFootcareAnalysis
}: AnalysisFlowPropType): JSX.Element => {
	const handleQuestionChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>, flag: boolean) => {
			const { name, value, checked } = event.target;
			setFootcareAnalysis((prevFootcareAnalysis: any) => {
				return Object.assign({}, prevFootcareAnalysis, {
					[name]: flag
				});
			});
		},
		[setFootcareAnalysis]
	);
	return (
		<div className="PHQ-intro theme-cont">
			<div className="theme-sub-cont">
				<div className="left-item" style={{ alignItems: "flex-start", flex: 2 }}>
					{/* <div className="question-container">
						<div className="question" style={{ fontSize: "3rem" }}>
							<span>
								<FaHandPointRight className="question-icon" />
							</span>
							&nbsp;&nbsp;&nbsp;{PHQ.general_Q5}
						</div>
						<div className="radio-container">
							<ThemeRadioButton
								checked={footcareAnalysis.general_Q5 === null ? false : footcareAnalysis.general_Q5}
								success
								handleChange={handleQuestionChange}
								label="Yes"
								name={"general_Q5"}
								fontSize="2rem"
							/>
							<ThemeRadioButton
								checked={footcareAnalysis.general_Q5 === null ? false : !footcareAnalysis.general_Q5}
								success={false}
								handleChange={handleQuestionChange}
								label="No"
								name={"general_Q5"}
								fontSize="2rem"
							/>
						</div>
					</div> */}
					<div className="question-container">
						<div className="question" style={{ fontSize: "3rem" }}>
							<span>
								<FaHandPointRight className="question-icon" />
							</span>
							&nbsp;&nbsp;&nbsp;{PHQ.general_Q6}
						</div>
						<div className="radio-container" style={{ display: "block" }}>
							<ThemeRadioButton
								checked={footcareAnalysis.general_Q6 === null ? false : footcareAnalysis.general_Q6}
								success
								handleChange={handleQuestionChange}
								label="Yes - Continue to Foot Pain Questionnaire & Diagnosis"
								name={"general_Q6"}
								fontSize="2rem"
								style={{
									width: "800px",
									fontWeight: "bold"
								}}
							/>
							<div className="mgb-3rem" />
							<ThemeRadioButton
								checked={footcareAnalysis.general_Q6 === null ? false : !footcareAnalysis.general_Q6}
								success={false}
								handleChange={handleQuestionChange}
								label="No - Review Product Recommendations Tailored To Your Feet"
								name={"general_Q6"}
								fontSize="2rem"
								style={{
									width: "800px",
									fontWeight: "bold"
								}}
							/>
						</div>
					</div>
				</div>
				<div className="right-item phq-intro-back-img"></div>
			</div>
		</div>
	);
};

export default PHQIntro;
