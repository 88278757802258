export const PHQ: any = {
	general_Q1: "Do you need to stand or walk for major part of your day?",
	general_Q2: "Are you recreational or professional  athlete?",
	general_Q3: "Are you Diabetic?",
	general_Q4: "Do you have corn/ callus?",
	general_Q5: "Do you have any Foot Deformity?",
	general_Q6: "Do you have Foot Pain?",
	general_Q7: "Is the pain aggravated walking barefoot?",
	general_Q8: "Does the pain feel like Sharp burning pain?",
	general_Q9: "Do you feel like there is a pebble when you stand or walk?",
	general_Q10: "Is the pain aggravated on prolonged standing and walking?",
	general_Q11: "Do you experience calf muscle pain at the end of the day?",
	general_Q12: "Is there less pain in the morning after a good night sleep?",
	general_Q13: "Is it painful if you press on the area that you pointed out?",
	general_Q14: "Is there any swelling over the area?",
	general_Q15: "Does it hurt when you take the first step in the morning?",
	general_Q16: "After you take a few steps after prolonged sitting?",
	general_Q17: "Is there any swelling over your heel?",
	general_Q18: "Does it hurt if you press on the swelling?",
	general_Q19: "Is it painful to move your ankle up and down?",
	general_Q20: "Is there any swelling around your big toe?",
	general_Q21: "Do you have any one of your toes riding up or pushing out an adjacent toe ? (Crossover toe)",
	general_Q22: "Is the pain aggravated on walking fast or running?",
	general_Q23: "Is it painful if you try to move your big toe?",
	general_Q24: "Is the pain maximum the undersurface of your big toe?",
	general_Q25: "Do you have a twisting injury in the past few days?",
	general_Q26: "Is there any ankle swelling?",
	general_Q27: "Past few weeks/ Month?",
	general_Q28: "Is there any ankle swelling?",
	general_Q29: "Do you feel your ankle is unstable (i,e) you get frequent ankle sprain?",
	general_Q30: "Is the pain aggravated by walking?",
	general_Q31: "Do you feel your pain is deep inside your ankle?",
	general_Q32: "Have been recent injury?",
	general_Q33: "Is the pain there more than two weeks?",
	general_Q34: "Is the pain aggravated by walking/ running?",
	general_Q35: "Does it hurt if someone presses hard over the area?",
	general_Q36: "Is there any local swelling over that part of the foot?",
	general_Q37: "Is there any history of a previous injury which required treatment?"
};

export const FIRST_PHQs = [
	{ label: PHQ.general_Q1, name: "general_Q1" },
	{ label: PHQ.general_Q2, name: "general_Q2" },
	{ label: PHQ.general_Q3, name: "general_Q3" },
	{ label: PHQ.general_Q4, name: "general_Q4" }
];

export const D1_PHQs = [
	{ label: PHQ.general_Q7, name: "general_Q7" },
	{ label: PHQ.general_Q8, name: "general_Q8" },
	{ label: PHQ.general_Q9, name: "general_Q9" }
];

export const D25_PHQs = [
	{ label: PHQ.general_Q10, name: "general_Q10" },
	{ label: PHQ.general_Q11, name: "general_Q11" },
	{ label: PHQ.general_Q12, name: "general_Q12" },
	{ label: PHQ.general_Q13, name: "general_Q13" },
	{ label: PHQ.general_Q14, name: "general_Q14" }
];

export const D3_PHQs = [
	{ label: PHQ.general_Q15, name: "general_Q15" },
	{ label: PHQ.general_Q16, name: "general_Q16" }
];

export const D4_PHQs = [
	{ label: PHQ.general_Q17, name: "general_Q17" },
	{ label: PHQ.general_Q18, name: "general_Q18" },
	{ label: PHQ.general_Q19, name: "general_Q19" }
];

export const D69_PHQs = [
	{ label: PHQ.general_Q20, name: "general_Q20" },
	{ label: PHQ.general_Q21, name: "general_Q21" },
	{ label: PHQ.general_Q22, name: "general_Q22" },
	{ label: PHQ.general_Q23, name: "general_Q23" },
	{ label: PHQ.general_Q24, name: "general_Q24" }
];

export const D7_PHQs = [
	{ label: PHQ.general_Q25, name: "general_Q25" },
	{ label: PHQ.general_Q26, name: "general_Q26" },
	{ label: PHQ.general_Q27, name: "general_Q27" },
	{ label: PHQ.general_Q28, name: "general_Q28" },
	{ label: PHQ.general_Q29, name: "general_Q29" },
	{ label: PHQ.general_Q30, name: "general_Q30" },
	{ label: PHQ.general_Q31, name: "general_Q31" }
];

export const D8_PHQs = [
	{ label: PHQ.general_Q32, name: "general_Q32" },
	{ label: PHQ.general_Q33, name: "general_Q33" },
	{ label: PHQ.general_Q34, name: "general_Q34" },
	{ label: PHQ.general_Q35, name: "general_Q35" },
	{ label: PHQ.general_Q36, name: "general_Q36" },
	{ label: PHQ.general_Q37, name: "general_Q37" }
];
