import { FC, forwardRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { SettingsProps } from "../ts/settings.types";
import LightControl from "./PhotoControl";
import IntegratedComponent from "./AvailableWebcams";

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Settings: FC<SettingsProps> = ({ openSettingsDialog, handleCloseSettingsDialog }: SettingsProps) => {
	const [tokens, setTokens] = useState({
		leftTop: "",
		rightTop: "",
		leftSide: "",
		rightSide: ""
	});

	const handleSetTokens = () => {
		// Store tokens in local storage
		localStorage.setItem("leftTop", tokens.leftTop);
		localStorage.setItem("rightTop", tokens.rightTop);
		localStorage.setItem("leftSide", tokens.leftSide);
		localStorage.setItem("rightSide", tokens.rightSide);
		const cameraIdsChangeEvent = new Event("cameraIdsChanged");
		window.dispatchEvent(cameraIdsChangeEvent);
	};

	return (
		<div>
			{/* <Button onClick={handleClickOpen}>
				<HomeIcon sx={{ fontSize: 50, color: "#5BB246" }} />
			</Button> */}
			<Dialog
				fullScreen
				open={openSettingsDialog}
				onClose={handleCloseSettingsDialog}
				TransitionComponent={Transition}
			>
				<AppBar sx={{ position: "relative", background: "#5FB142" }}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={handleCloseSettingsDialog} aria-label="close">
							<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
							SETTINGS
						</Typography>
						<Button autoFocus color="inherit" onClick={handleCloseSettingsDialog}>
							<Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
								SAVE
							</Typography>
						</Button>
					</Toolbar>
				</AppBar>
				<LightControl />
				<IntegratedComponent handleCloseSettingsDialog={handleCloseSettingsDialog} />
				{/* <div className="text-center">
					<Button
						sx={{
							textAlign: "center",
							fontSize: "2.6rem",
							fontFamily: "inherit",
							fontWeight: "bold",
							paddingLeft: "2rem",
							paddingRight: "2rem",
							background: "#60cf63",
							color: "#1f2149"
						}}
						disabled={false}
						size="large"
						onClick={handleCloseSettingsDialog}
					>
						Save
					</Button>
				</div> */}
			</Dialog>
		</div>
	);
};

export default Settings;
