import { createContext } from "react";

export type AuthContextProps = {
	isLoggedIn: boolean;
	setLogIn: React.Dispatch<React.SetStateAction<boolean>>;
};

const AuthContext = createContext({} as AuthContextProps);

export default AuthContext;
