import { PHQ } from "../../constants/PHQ";

type TQuestion = {
	question: string;
	is_correct: boolean;
};
export const formatQuestionList = (questionList: any) => {
	const formattedQuestionList: TQuestion[] = [];
	for (const question of questionList) {
		const formatQuestion = {
			question: PHQ[question.key],
			is_correct: question.value
		};
		formattedQuestionList.push(formatQuestion);
	}
	console.log("====>question", formattedQuestionList);
	return formattedQuestionList;
};
