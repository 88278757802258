import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import WebcamCard from "../../../components/WebcamCard";
import ImageCaptureButton from "../../../components/CaptureButton";
import { AnalysisFlowPropType } from "../../../ts/propTypes/FootcareAnalysisProps.types";
import ImageScan from "../../imageScan/ImageScan";
import { IconButton } from "@mui/material";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";

function WebcamPage({ setCurrentIdx, setFootcareAnalysis, footcareAnalysis }: AnalysisFlowPropType) {
	const [leftTopDeviceId, setLeftTopDeviceId] = useState(localStorage.getItem("leftTop") || "");
	const [rightTopDeviceId, setRightTopDeviceId] = useState(localStorage.getItem("rightTop") || "");
	const [leftSideDeviceId, setLeftSideDeviceId] = useState(localStorage.getItem("leftSide") || "");
	const [rightSideDeviceId, setRightSideDeviceId] = useState(localStorage.getItem("rightSide") || "");

	const handleRerenderCameras = () => {
		// Set camera IDs to null after a delay
		setTimeout(() => {
			setLeftTopDeviceId("");
			setRightTopDeviceId("");
			setLeftSideDeviceId("");
			setRightSideDeviceId("");

			// Retrieve values from local storage after a delay
			setTimeout(() => {
				setLeftTopDeviceId(localStorage.getItem("leftTop") || "");
				setRightTopDeviceId(localStorage.getItem("rightTop") || "");
				setLeftSideDeviceId(localStorage.getItem("leftSide") || "");
				setRightSideDeviceId(localStorage.getItem("rightSide") || "");
			}, 1000); // Adjust delay as needed
		}, 1000); // Adjust delay as needed
	};

	const [leftTopverticalValues, setLeftTopVerticalValues] = useState(
		JSON.parse(localStorage.getItem("leftTopVerticalValue") || "[0,100]") as number[]
	);
	const [leftTophorizontalValues, setLeftTopHorizontalValues] = useState(
		JSON.parse(localStorage.getItem("leftTopHorizentalValue") || "[0,100]") as number[]
	);
	const [leftSideverticalValues, setLeftSideVerticalValues] = useState(
		JSON.parse(localStorage.getItem("leftSideVerticalValue") || "[0,100]") as number[]
	);
	const [leftSidehorizontalValues, setLeftSideHorizontalValues] = useState(
		JSON.parse(localStorage.getItem("leftSideHorizentalValue") || "[0,100]") as number[]
	);
	const [rightTopverticalValues, setRightTopVerticalValues] = useState(
		JSON.parse(localStorage.getItem("rightTopVerticalValue") || "[0,100]") as number[]
	);
	const [rightTophorizontalValues, setRightTopHorizontalValues] = useState(
		JSON.parse(localStorage.getItem("rightTopHorizentalValue") || "[0,100]") as number[]
	);
	const [rightSideverticalValues, setRightSideVerticalValues] = useState(
		JSON.parse(localStorage.getItem("rightSideVerticalValue") || "[0,100]") as number[]
	);
	const [rightSidehorizontalValues, setRightSideHorizontalValues] = useState(
		JSON.parse(localStorage.getItem("rightSideHorizentalValue") || "[0,100]") as number[]
	);

	// const [leftTopverticalValues, setLeftTopVerticalValues] = useState([0, 100]);
	// const [leftTophorizontalValues, setLeftTopHorizontalValues] = useState([0, 100]);
	// const [leftSideverticalValues, setLeftSideVerticalValues] = useState([0, 100]);
	// const [leftSidehorizontalValues, setLeftSideHorizontalValues] = useState([0, 100]);
	// const [rightTopverticalValues, setRightTopVerticalValues] = useState([0, 100]);
	// const [rightTophorizontalValues, setRightTopHorizontalValues] = useState([0, 100]);
	// const [rightSideverticalValues, setRightSideVerticalValues] = useState([0, 100]);
	// const [rightSidehorizontalValues, seRightSideHorizontalValues] = useState([0, 100]);

	useEffect(() => {
		handleRerenderCameras();
		const updateCameraIds = () => {
			setLeftTopDeviceId(localStorage.getItem("leftTop") || "");
			setRightTopDeviceId(localStorage.getItem("rightTop") || "");
			setLeftSideDeviceId(localStorage.getItem("leftSide") || "");
			setRightSideDeviceId(localStorage.getItem("rightSide") || "");

			setLeftTopVerticalValues(JSON.parse(localStorage.getItem("leftTopVerticalValue") || "[0,100]") as number[]);
			setLeftTopHorizontalValues(
				JSON.parse(localStorage.getItem("leftTopHorizentalValue") || "[0,100]") as number[]
			);
			setLeftSideVerticalValues(
				JSON.parse(localStorage.getItem("leftSideVerticalValue") || "[0,100]") as number[]
			);
			setLeftSideHorizontalValues(
				JSON.parse(localStorage.getItem("leftSideHorizentalValue") || "[0,100]") as number[]
			);
			setRightTopVerticalValues(
				JSON.parse(localStorage.getItem("rightTopVerticalValue") || "[0,100]") as number[]
			);
			setRightTopHorizontalValues(
				JSON.parse(localStorage.getItem("rightTopHorizentalValue") || "[0,100]") as number[]
			);
			setRightSideVerticalValues(
				JSON.parse(localStorage.getItem("rightSideVerticalValue") || "[0,100]") as number[]
			);
			setRightSideHorizontalValues(
				JSON.parse(localStorage.getItem("rightSideHorizentalValue") || "[0,100]") as number[]
			);
		};

		window.addEventListener("storage", updateCameraIds);
		window.addEventListener("cameraIdsChanged", updateCameraIds);

		return () => {
			window.removeEventListener("storage", updateCameraIds);
			window.removeEventListener("cameraIdsChanged", updateCameraIds);
		};
	}, []);

	return (
		<Box sx={{ maxWidth: "60%", margin: "0 auto" }}>
			<IconButton
				onClick={handleRerenderCameras}
				aria-label="fingerprint"
				color="secondary"
				style={{ position: "absolute", left: "50px", bottom: "200px", border: "2px solid green" }}
			>
				<CameraswitchIcon style={{ color: "green", fontSize: "50px" }} />
			</IconButton>

			<Box sx={localStorage.getItem("showCamera") !== "true" ? { display: "none" } : { display: "block" }}>
				<Box component="ul" sx={{ display: "flex", gap: 0, flexWrap: "wrap", p: 0, marginTop: "1.5rem" }}>
					<Card
						component="li"
						sx={{
							width: "20%",
							flexGrow: 1,
							position: "relative",
							padding: 0,
							margin: 0,
							marginRight: "3rem",
							border: "5px solid #30B02D"
						}}
					>
						<WebcamCard
							selectedDeviceId={leftSideDeviceId}
							verticalValue={leftSideverticalValues}
							horizontalValue={leftSidehorizontalValues}
						/>
					</Card>

					<Card
						component="li"
						sx={{
							width: "20%",
							flexGrow: 1,
							position: "relative",
							padding: 0,
							marginBottom: 0,
							border: "5px solid #30B02D"
						}}
					>
						<WebcamCard
							selectedDeviceId={leftTopDeviceId}
							verticalValue={leftTopverticalValues}
							horizontalValue={leftTophorizontalValues}
						/>
					</Card>
				</Box>
				<Box component="ul" sx={{ display: "flex", gap: 2, flexWrap: "wrap", p: 0, marginTop: "3rem" }}>
					<Card
						component="li"
						sx={{
							width: "20%",
							flexGrow: 1,
							position: "relative",
							padding: 0,
							marginRight: "3rem",
							border: "5px solid #30B02D"
						}}
					>
						<WebcamCard
							selectedDeviceId={rightTopDeviceId}
							verticalValue={rightTopverticalValues}
							horizontalValue={rightTophorizontalValues}
						/>
					</Card>

					<Card
						component="li"
						sx={{
							width: "20%", // Set the card width to 20%
							flexGrow: 1,
							position: "relative", // Required for absolute positioning of video
							padding: 0,
							marginBottom: 0,
							border: "5px solid #30B02D"
						}}
					>
						<WebcamCard
							selectedDeviceId={rightSideDeviceId}
							verticalValue={rightSideverticalValues}
							horizontalValue={rightSidehorizontalValues}
						/>
					</Card>
				</Box>
			</Box>

			{localStorage.getItem("showCamera") !== "true" ? <ImageScan /> : null}

			<ImageCaptureButton
				footcareAnalysis={footcareAnalysis}
				setFootcareAnalysis={setFootcareAnalysis}
				setCurrentIdx={setCurrentIdx}
			/>
		</Box>
	);
}

export default WebcamPage;
