import React, { useCallback, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, Button, CardActionArea, CardActions } from "@mui/material";
import BasicModal from "./TextModal";

interface product {
	product_image: string;
	product_name: string;
	product_category: string;
	product_area: string;
	description: string;
}

type MultiActionAreaCardProps = {
	productDetails: product;
};

const MultiActionAreaCard = ({ productDetails }: MultiActionAreaCardProps) => {
	const [open, setOpen] = useState(false);

	const handleModalOperation = useCallback((op: "OPEN" | "CLOSE") => {
		op === "OPEN" ? setOpen(true) : op === "CLOSE" ? setOpen(false) : null;
	}, []);

	return (
		<Box sx={{ paddingLeft: "2%" }}>
			{open && (
				<BasicModal open={open} handleModalOperation={handleModalOperation} productData={productDetails} />
			)}
			<Card sx={{ width: 400, borderRadius: "50px", maxHeight: "1fr" }}>
				<CardActionArea onClick={() => handleModalOperation("OPEN")}>
					<CardMedia
						component="img"
						height="300"
						image={productDetails.product_image}
						alt="product image"
						sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
					/>
					<CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
						<Typography
							gutterBottom
							variant="h5"
							component="div"
							color="#5cb85c"
							fontFamily={"Quicksand"}
							fontWeight={"bold"}
						>
							{productDetails.product_name}
						</Typography>
						<Typography fontWeight={"bold"} fontFamily={"Quicksand"} variant="body1" color="text.secondary">
							{productDetails.product_area}
						</Typography>
						<Typography fontWeight={"bold"} fontFamily={"Quicksand"} variant="body1" color="text.secondary">
							{productDetails.product_category}
						</Typography>
					</CardContent>
				</CardActionArea>
				{/* <CardActions>
					<Button size="small" color="primary" onClick={() => handleModalOperation("OPEN")}>
						Know More
					</Button>
				</CardActions> */}
			</Card>
		</Box>
	);
};

export default MultiActionAreaCard;
