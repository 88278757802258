import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

export const FullHeightBox = styled(Box)(({ theme }) => ({
	flex: 1,
	display: "flex",
	justifyContent: "center",
	alignItems: "center"
}));

export const LoginCard = styled(Card)(({ theme }) => ({
	width: "calc(100% - 200px)",
	height: "calc(100% - 400px)",
	maxWidth: "600px",
	padding: theme.spacing(2),
	boxShadow: theme.shadows[4],
	textAlign: "center",
	marginLeft: theme.spacing(2),
	marginRight: theme.spacing(2),
	display: "flex", // Added to use flex layout
	flexDirection: "column", // Stack content vertically
	justifyContent: "center", // Align content vertically in the middle
	alignItems: "center" // Align content horizontally in the center
}));

export const CustomKioskLogin = styled(Typography)(({ theme }) => ({
	fontSize: "20px",
	fontWeight: "Light",
	fontFamily: "Quicksand",
	margin: "0",
	[theme.breakpoints.up("sm")]: {
		fontSize: "32px"
	},
	[theme.breakpoints.up("md")]: {
		fontSize: "48px"
	},
	[theme.breakpoints.up("lg")]: {
		fontSize: "64px"
	}
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
	width: "100%",
	"& .MuiInputBase-root": {
		"& input:focus": {
			backgroundColor: "#f0f0f0"
		}
	}
}));
