import React, { useEffect } from "react";

const VideoLoopPage = (): JSX.Element => {
	return (
		<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
			<video autoPlay loop width="50%" height="75%" src="/flower.mp4">
				{/* Add additional source elements for different video formats if needed */}
				Your browser does not support the video tag.
			</video>
		</div>
	);
};

export default VideoLoopPage;
