import React from "react";
import { ErrorPhoto, sadFace } from "../../../assets";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
	const navigate = useNavigate();
	const handleRouteLanding = () => {
		navigate("/analyse");
	};
	return (
		<div
			className="error"
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				gap: "2",
				alignItems: "center",
				height: "80vh"
			}}
		>
			<img src={sadFace} style={{ width: 400 }} />
			<h2>Please Place Your Feet Properly</h2>
			<Button className="tryAgain" onClick={handleRouteLanding}>
				Try Again
			</Button>
		</div>
	);
};

export default ErrorPage;
