import StartPage from "./pages/StartPage";
import LoginPage from "./pages/LoginPage";
// import SettingsPage from "./pages/SettingsPage";
import Landing from "./pages/landing/Landing";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import AppBar from "./components/shared/appBar/AppBar";
import { useEffect } from "react";
import AnalysisFlow from "./pages/analysisFlow/AnalysisFlow";
import VideoLoopPage from "./pages/default/default";
import TwoBoxPage from "./pages/LoginPage";
import { createContext, useState } from "react";
import AuthContextProvider from "./context/AuthContext/Store";
import ErrorPage from "./components/shared/errorPage/ErrorPage";

const UserLogin = createContext({
	isLoggedIn: false,
	setisLoggedIn: () => Function
});

const App = () => {
	useEffect(() => {
		// Check if getUserMedia is available in the browser
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			// Request permission to access the webcam
			navigator.mediaDevices
				.getUserMedia({ video: true })
				.then((stream: MediaStream) => {
					// You can use the webcam stream here if needed
				})
				.catch((error: unknown) => {
					console.error("Error accessing webcam:", error);
				});
		} else {
			console.error("getUserMedia is not supported in this browser");
		}
	}, []);

	return (
		<div className="App">
			<AuthContextProvider>
				<BrowserRouter>
					<AppBar />
					<Routes>
						<Route path="/" element={<LoginPage />} />
						<Route path="/start" element={<VideoLoopPage />} />
						<Route path="/landing" element={<Landing />} />
						<Route path="/analyse" element={<AnalysisFlow />} />
						<Route path="/login" element={<TwoBoxPage />} />
						<Route path="/error" element={<ErrorPage />} />
					</Routes>
				</BrowserRouter>
			</AuthContextProvider>
		</div>
	);
};

export default App;
