import React, { FC, useState, useContext } from "react";
import PersonalInformation from "./personalInformation/PersonalInformation";
import GeneralQuestions from "./generalQuestions/GeneralQuestions";
import IndentifyingInformation from "./identifyingInformation/IndentifyingInformation";
import PHQIntro from "./PHQ/PHQIntro/PHQIntro";
import ScanReport from "./scanReport/ScanReport";
import LeftPainPoint from "./painPoints/leftPainPoint/LeftPainPoint";
import RightPainPoint from "./painPoints/rightPainPoint/RightPainPoint";
import WebcamPage from "./captureFoot/WebcamPage";
import PHQCore from "./PHQ/PHQCore/PHQCore";
import LeftFootProfile from "./footProfile/leftFootProfile/LeftFootProfile";
import RightFootProfile from "./footProfile/rightFootProfile/RightFootProfile";
import Recommendations from "./recommendations/Recommendations";
import AuthContext from "../../context/AuthContext/AuthContext";

import "./analysisFlow.css";
import { NextArrow, PrevArrow } from "../../assets";
import { useNavigate } from "react-router-dom";
//import CommonModal from "../../components/shared/modal/CommonModal";
import { Typography } from "@mui/material";
import NotLoggedInCard from "../../components/NotLoggedInCard";

const AnalysisFlow: FC = (): JSX.Element => {
	const { isLoggedIn, setLogIn } = useContext(AuthContext);
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [footcareAnalysis, setFootcareAnalysis] = useState<any>({
		name: "",
		gender: "",
		minimum_age: 0,
		maximum_age: 0,
		general_Q1: null,
		general_Q2: null,
		general_Q3: null,
		general_Q4: null,
		general_Q5: null,
		general_Q6: null,
		left_length: 254,
		left_width: 104,
		left_instep_height: 77,
		left_arch_height: 21,
		right_length: 253,
		right_width: 99,
		right_instep_height: 68,
		right_arch_height: 15,
		left_shoe_size: 8,
		right_shoe_size: 9,
		left_arch_type: "High",
		right_arch_type: "Low",
		left_width_type: null,
		right_width_type: null,
		left_pain_point_1: false,
		left_pain_point_2: false,
		left_pain_point_3: false,
		left_pain_point_4: false,
		left_pain_point_5: false,
		left_pain_point_6: false,
		left_pain_point_7: false,
		left_pain_point_8: false,
		right_pain_point_1: false,
		right_pain_point_2: false,
		right_pain_point_3: false,
		right_pain_point_4: false,
		right_pain_point_5: false,
		right_pain_point_6: false,
		right_pain_point_7: false,
		right_pain_point_8: false,
		LEFT: {
			general_Q1: null,
			general_Q2: null,
			general_Q3: null,
			general_Q4: null,
			general_Q5: null,
			general_Q6: null,
			general_Q7: null,
			general_Q8: null,
			general_Q9: null,
			general_Q10: null,
			general_Q11: null,
			general_Q12: null,
			general_Q13: null,
			general_Q14: null,
			general_Q15: null,
			general_Q16: null,
			general_Q17: null,
			general_Q18: null,
			general_Q19: null,
			general_Q20: null,
			general_Q21: null,
			general_Q22: null,
			general_Q23: null,
			general_Q24: null,
			general_Q25: null,
			general_Q26: null,
			general_Q27: null,
			general_Q28: null,
			general_Q29: null,
			general_Q30: null,
			general_Q31: null,
			general_Q32: null,
			general_Q33: null,
			general_Q34: null,
			general_Q35: null,
			general_Q36: null,
			general_Q37: null
		},
		RIGHT: {
			general_Q1: null,
			general_Q2: null,
			general_Q3: null,
			general_Q4: null,
			general_Q5: null,
			general_Q6: null,
			general_Q7: null,
			general_Q8: null,
			general_Q9: null,
			general_Q10: null,
			general_Q11: null,
			general_Q12: null,
			general_Q13: null,
			general_Q14: null,
			general_Q15: null,
			general_Q16: null,
			general_Q17: null,
			general_Q18: null,
			general_Q19: null,
			general_Q20: null,
			general_Q21: null,
			general_Q22: null,
			general_Q23: null,
			general_Q24: null,
			general_Q25: null,
			general_Q26: null,
			general_Q27: null,
			general_Q28: null,
			general_Q29: null,
			general_Q30: null,
			general_Q31: null,
			general_Q32: null,
			general_Q33: null,
			general_Q34: null,
			general_Q35: null,
			general_Q36: null,
			general_Q37: null
		},
		left_diagnosis: [],
		left_diagnosis_pain_points: [],
		right_diagnosis: [],
		right_diagnosis_pain_points: [],
		weight: 0
	});
	const navigate = useNavigate();

	const [currentIdx, setCurrentIdx] = useState(0);
	//alert(currentIdx);
	const handleNextClick = () => {
		if (currentIdx === 0) {
			if (
				footcareAnalysis.gender === "" ||
				footcareAnalysis.minimum_age === 0 ||
				footcareAnalysis.maximum_age === 0
			) {
				handleOpen();
				return;
			}
		}
		if (currentIdx === 12) {
			navigate("/landing");
			return;
		}
		const {
			left_pain_point_1,
			left_pain_point_2,
			left_pain_point_3,
			left_pain_point_4,
			left_pain_point_5,
			left_pain_point_6,
			left_pain_point_7,
			left_pain_point_8,
			right_pain_point_1,
			right_pain_point_2,
			right_pain_point_3,
			right_pain_point_4,
			right_pain_point_5,
			right_pain_point_6,
			right_pain_point_7,
			right_pain_point_8
		} = footcareAnalysis;
		if (footcareAnalysis.general_Q6 === false && currentIdx === 5) {
			setCurrentIdx(12);
		} else if (
			[
				left_pain_point_1,
				left_pain_point_2,
				left_pain_point_3,
				left_pain_point_4,
				left_pain_point_5,
				left_pain_point_6,
				left_pain_point_7,
				left_pain_point_8
			].every((painPoint: boolean) => painPoint === false) &&
			currentIdx === 6
		) {
			setCurrentIdx(8);
		} else if (
			[
				right_pain_point_1,
				right_pain_point_2,
				right_pain_point_3,
				right_pain_point_4,
				right_pain_point_5,
				right_pain_point_6,
				right_pain_point_7,
				right_pain_point_8
			].every((painPoint: boolean) => painPoint === false) &&
			currentIdx === 8
		) {
			setCurrentIdx(10);
		} else {
			setCurrentIdx((prevCurrentIdx: number) => prevCurrentIdx + 1);
		}
	};

	const handlePrevClick = () => {
		if (currentIdx === 0) {
			navigate("/landing");
			return;
		}
		const {
			left_pain_point_1,
			left_pain_point_2,
			left_pain_point_3,
			left_pain_point_4,
			left_pain_point_5,
			left_pain_point_6,
			left_pain_point_7,
			left_pain_point_8,
			right_pain_point_1,
			right_pain_point_2,
			right_pain_point_3,
			right_pain_point_4,
			right_pain_point_5,
			right_pain_point_6,
			right_pain_point_7,
			right_pain_point_8
		} = footcareAnalysis;

		if ((footcareAnalysis.general_Q6 === false || footcareAnalysis.general_Q6 === null) && currentIdx === 12) {
			setCurrentIdx(5);
		} else if (
			[
				left_pain_point_1,
				left_pain_point_2,
				left_pain_point_3,
				left_pain_point_4,
				left_pain_point_5,
				left_pain_point_6,
				left_pain_point_7,
				left_pain_point_8
			].every((painPoint: boolean) => painPoint === false) &&
			currentIdx === 8
		) {
			setCurrentIdx(6);
		} else if (
			[
				right_pain_point_1,
				right_pain_point_2,
				right_pain_point_3,
				right_pain_point_4,
				right_pain_point_5,
				right_pain_point_6,
				right_pain_point_7,
				right_pain_point_8
			].every((painPoint: boolean) => painPoint === false) &&
			currentIdx === 10
		) {
			setCurrentIdx(8);
		} else {
			setCurrentIdx((prevCurrentIdx: number) => prevCurrentIdx - 1);
		}
	};

	console.log(footcareAnalysis);

	// alert(currentIdx);

	return (
		<>
			{!isLoggedIn ? (
				<NotLoggedInCard />
			) : (
				<div>
					{/* <React.Fragment key={71}>{stages[currentIdx]}</React.Fragment> */}

					{currentIdx === 0 ? (
						<PersonalInformation
							footcareAnalysis={footcareAnalysis}
							setFootcareAnalysis={setFootcareAnalysis}
							setCurrentIdx={setCurrentIdx}
						/>
					) : null}
					{currentIdx === 1 ? (
						<WebcamPage
							footcareAnalysis={footcareAnalysis}
							setCurrentIdx={setCurrentIdx}
							setFootcareAnalysis={setFootcareAnalysis}
						/>
					) : null}
					{currentIdx === 2 ? (
						<GeneralQuestions
							footcareAnalysis={footcareAnalysis}
							setFootcareAnalysis={setFootcareAnalysis}
							setCurrentIdx={setCurrentIdx}
						/>
					) : null}
					{currentIdx === 3 ? (
						<IndentifyingInformation
							footcareAnalysis={footcareAnalysis}
							setFootcareAnalysis={setFootcareAnalysis}
							setCurrentIdx={setCurrentIdx}
						/>
					) : null}

					{currentIdx === 4 ? (
						<ScanReport
							footcareAnalysis={footcareAnalysis}
							setFootcareAnalysis={setFootcareAnalysis}
							setCurrentIdx={setCurrentIdx}
						/>
					) : null}
					{currentIdx === 5 ? (
						<PHQIntro
							footcareAnalysis={footcareAnalysis}
							setFootcareAnalysis={setFootcareAnalysis}
							setCurrentIdx={setCurrentIdx}
						/>
					) : null}
					{currentIdx === 6 ? (
						<LeftPainPoint
							footcareAnalysis={footcareAnalysis}
							setFootcareAnalysis={setFootcareAnalysis}
							setCurrentIdx={setCurrentIdx}
						/>
					) : null}
					{currentIdx === 7 ? (
						<PHQCore
							footcareAnalysis={footcareAnalysis}
							setFootcareAnalysis={setFootcareAnalysis}
							setCurrentIdx={setCurrentIdx}
							foot="LEFT"
						/>
					) : null}
					{currentIdx === 8 ? (
						<RightPainPoint
							footcareAnalysis={footcareAnalysis}
							setFootcareAnalysis={setFootcareAnalysis}
							setCurrentIdx={setCurrentIdx}
						/>
					) : null}
					{currentIdx === 9 ? (
						<PHQCore
							footcareAnalysis={footcareAnalysis}
							setFootcareAnalysis={setFootcareAnalysis}
							setCurrentIdx={setCurrentIdx}
							foot="RIGHT"
						/>
					) : null}
					{currentIdx === 10 ? (
						<LeftFootProfile
							footcareAnalysis={footcareAnalysis}
							setFootcareAnalysis={setFootcareAnalysis}
							setCurrentIdx={setCurrentIdx}
						/>
					) : null}
					{currentIdx === 11 ? (
						<RightFootProfile
							footcareAnalysis={footcareAnalysis}
							setFootcareAnalysis={setFootcareAnalysis}
							setCurrentIdx={setCurrentIdx}
						/>
					) : null}
					{currentIdx === 12 ? (
						<Recommendations
							footcareAnalysis={footcareAnalysis}
							setFootcareAnalysis={setFootcareAnalysis}
							setCurrentIdx={setCurrentIdx}
						/>
					) : null}
					{currentIdx === 1 || currentIdx === 3 ? null : (
						<div className="bottom-pointer-right" onClick={handleNextClick}>
							<img src={NextArrow} />
							{/* <GrNext className="icon-1" />
					<GrNext className="icon-2" /> */}
						</div>
					)}
					<div className="bottom-pointer-left" onClick={handlePrevClick}>
						<img src={PrevArrow} />
						{/* <FcPrevious className="icon-3" />
				<FcPrevious className="icon-4" /> */}
					</div>
					{/*<CommonModal open={open} handleClose={handleClose} />*/}
				</div>
			)}
		</>
	);
};

export default AnalysisFlow;
