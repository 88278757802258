import { FC } from "react";
import { AnalysisFlowPropType } from "../../../../ts/propTypes/FootcareAnalysisProps.types";
import { PainPointGeneral } from "../../../../assets";

import "./painPoint.css";

type PainPointProps = {
	type: "LEFT" | "RIGHT";
	handlePainPointChange: (painPoint: string) => void;
} & AnalysisFlowPropType;

const PainPoint: FC<PainPointProps> = ({
	footcareAnalysis,
	setFootcareAnalysis,
	handlePainPointChange,
	type
}: PainPointProps): JSX.Element => {
	return (
		<div className="pain-point-cont">
			<div className="pain-point-background" />
			<div
				className={
					"pain-point-1 " +
					(footcareAnalysis[`${type.toLocaleLowerCase()}_pain_point_1`]
						? "pain-point-item-selected"
						: "pain-point-item")
				}
				onClick={() => handlePainPointChange(`${type.toLocaleLowerCase()}_pain_point_1`)}
			>
				1
			</div>
			<div
				className={
					"pain-point-2 " +
					(footcareAnalysis[`${type.toLocaleLowerCase()}_pain_point_2`]
						? "pain-point-item-selected"
						: "pain-point-item")
				}
				onClick={() => handlePainPointChange(`${type.toLocaleLowerCase()}_pain_point_2`)}
			>
				2
			</div>
			<div
				className={
					"pain-point-3 " +
					(footcareAnalysis[`${type.toLocaleLowerCase()}_pain_point_3`]
						? "pain-point-item-selected"
						: "pain-point-item")
				}
				onClick={() => handlePainPointChange(`${type.toLocaleLowerCase()}_pain_point_3`)}
			>
				3
			</div>
		</div>
	);
};

export default PainPoint;
