const diagnosisData = {
	"Planter Fasciitis": {
		text: "This is an inflammation of the fibrous tissue (plantar fascia) along the bottom of your foot that connects your heel bone to your toes. Plantar fasciitis can cause intense heel pain. Plantar fasciitis (PLAN-tur fas-e-I-tis) is one of the most common causes of heel pain.",
		mainPara:
			"Plantar fasciitis (PLAN-tur fas-e-I-tis) is one of the most common causes of heel pain. It involves inflammation of a thick band of tissue that runs across the bottom of each foot and connects the heel bone to the toes, known as the plantar fascia. Plantar fasciitis commonly causes stabbing pain that often occurs with your first steps in the morning. As you get up and move, the pain normally decreases, but it might return after long periods of standing or when you stand up after sitting.",
		secondText: "Planter Fascitis is commonly seen in:",
		secondPara:
			"The plantar fascia is a band of tissue, called fascia, that connects your heel bone to the base of your toes. It supports the arch of the foot and absorbs shock when walking. Tension and stress on the fascia can cause small tears. Repeated stretching and tearing of the facia can irritate or inflame it, although the cause remains unclear in many cases of plantar fasciitis."
	},
	"Metatarsalgia/ Morton’s Neuroma": {
		text: "Morton's neuroma is a painful condition that affects the ball of your foot, most commonly the area between your third and fourth toes. Morton's neuroma may feel as if you are standing on a pebble in your shoe or on a fold in your sock.",
		mainPara:
			"Morton's neuroma is a painful condition that affects the ball of your foot, most commonly the area between your third and fourth toes. Morton's neuroma may feel as if you are standing on a pebble in your shoe or on a fold in your sock. Morton's neuroma involves a thickening of the tissue around one of the nerves leading to your toes. This can cause a sharp, burning pain in the ball of your foot. You may have stinging, burning or numbness in the affected toes.",
		secondText: "What are Morton’s neuroma symptoms?",
		secondPara:
			"(a) Pain between your toes when you stand or walk. (b) Pain that’s worse when you wear high-heeled shoes or stand on the balls of your feet. (c) Swelling between your toes. (d) Tingling (feeling pins and needles) and numbness in your foot."
	},
	"Tibial post tendinitis": {
		text: "Posterior tibialis tendonitis is an injury of the posterior tibialis tendon in the back of the inner ankle. This tendon connects the muscles of the leg to the inner foot. It is important for standing on the toes, the pushing-off phase of running or jumping.",
		mainPara:
			"Posterior tibial tendon dysfunction (PTTD) is an issue that causes foot and ankle pain. It’s also known as posterior tibial tendonitis or posterior tibial tendon insufficiency. The posterior tibial tendon connects your calf muscle to bones on the inside of your foot. The main purpose of the tendon is to support the arch on the inside of your foot. When the tendon is injured or breaks down, it may no longer be able to support the arch.",
		secondText: "What are the stages of posterior tibial tendon dysfunction?",
		secondPara:
			"Stage I: The tendon is injured but otherwise intact; Stage II: The tendon is torn (ruptured) or not working properly. The foot is deformed; Stage III: The foot is significantly deformed. There are degenerative changes to the connective tissue (cartilage) in the back of the foot; Stage IV: There are degenerative changes to the ankle joint."
	},
	"Achilles Tendinitis": {
		text: "Achilles tendinitis is an overuse injury of the Achilles (uh-KILL-eez) tendon, the band of tissue that connects calf muscles at the back of the lower leg to your heel bone. The Achilles tendon is the largest tendon in the body.",
		mainPara:
			"Achilles tendinitis is a common condition that occurs when the large tendon that runs down the back of the lower leg becomes irritated and inflamed.  It connects the calf muscles to the heel bone and is used when you walk, run, climb stairs, jump, and stand on your tip toes. Although the Achilles tendon can withstand great stresses from running and jumping, it is also prone to tendinitis, a condition associated with overuse.",
		secondText: "Symptoms of Achilles Tendinitis are:",
		secondPara:
			"The pain associated with Achilles tendinitis typically begins as a mild ache in the back of the leg or above the heel after running or other sports activity. Episodes of more-severe pain may occur after prolonged running, stair climbing or sprinting.You might also experience tenderness or stiffness, especially in the morning, which usually improves with mild activity."
	},
	"Hallux rigidus": {
		text: "Hallux rigidus is a type of arthritis in your big toe. Specifically, it affects your big toe joint — the metatarsophalangeal (MTP) joint. Your MTP joint is where the base of your big toe meets your foot. Arthritis is a general term for a group of more than 100 diseases. ",
		mainPara:
			"Plantar fasciitis (PLAN-tur fas-e-I-tis) is one of the most common causes of heel pain. It involves inflammation of a thick band of tissue that runs across the bottom of each foot and connects the heel bone to the toes, known as the plantar fascia. Plantar fasciitis commonly causes stabbing pain that often occurs with your first steps in the morning. As you get up and move, the pain normally decreases, but it might return after long periods of standing or when you stand up after sitting.",
		secondText: "How common is hallux rigidus?",
		secondPara:
			"Experts estimate that around 1 in 40 adults older than 50 have hallux rigidus. It’s the most common type of foot arthritis. Hallux rigidus is the second most common MTP joint condition after bunions (hallux valgus)."
	},
	"Ankle Sprain": {
		text: "A sprained ankle is an injury that occurs when you roll, twist or turn your ankle in an awkward way. This can stretch or tear the tough bands of tissue (ligaments) that help hold your ankle bones together. Ligaments help stabilize joints, preventing excessive movement. ",
		mainPara:
			"Ankle sprains are common injuries that occur among people of all ages and at all activity levels; in fact, they are the number one reason for missed participation in athletics. An ankle sprain occurs when the strong ligaments that support the ankle stretch beyond their limits and tear. The severity of a sprain can vary greatly depending on the number of ligaments involved and the extent to which the ligaments are torn. A sprained ankle occurs when the ligaments are forced beyond their normal range of motion. Most sprained ankles involve injuries to the ligaments on the outer side of the ankle.",
		secondText: "When to see a doctor?",
		secondPara:
			"Call your doctor if you have pain and swelling in your ankle and you suspect a sprain. Self-care measures may be all you need, but talk to your doctor to discuss whether you should have your ankle evaluated. If signs and symptoms are severe, you may have significant damage to a ligament or a broken bone in your ankle or lower leg."
	},
	"Midfoot Arthritis": {
		text: "Midfoot arthritis is characterized by pain and swelling in the midfoot, aggravated by standing and walking. There is often an associated bony prominence on the top of the foot.",
		mainPara:
			"Midfoot arthritis is joint pain and inflammation that impacts the bones that make up the middle part of the foot. This includes the tarso-metatarsal (TMT) joints and naviculo-cuneiform joints (NCJ). The TMT joints connect the tarsals and the metatarsals. The tarsals are the five bones that make up the arch of the foot. The metatarsals connect these five bones to the phalanges, or bones of the toes.",
		secondText: "Symptoms of midfoot arthritis:",
		secondPara:
			"(a) pain when the bones move, such as walking or even standing (b) pain that worsens with intense activity (c) swelling, pain, or warmth and (d) tenderness or pain when pressure is applied to the midfoot"
	}
};

export const DIAGNOSIS_DATA = diagnosisData;
