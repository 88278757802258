const DL_server_url_local = "";
const DL_server_url_prod = "";
// const DL_server_url_dev = "https://backendcurafoot.localto.net";
const DL_server_url_dev = "http://127.0.0.1:8000/";

// const DL_server_url_dev = "https://122.176.164.234:8000/";

export const DL_server_url = "https://poodle-allowed-salmon.ngrok-free.app/"; //"http://127.0.0.1:8000/";

export const crm_server_url = "https://d2w39smwctjse1.cloudfront.net/";
