import { useCallback, useReducer, useSyncExternalStore, useState } from "react";
import AuthContext from "./AuthContext";

const AuthContextProvider = ({ children }: any) => {
	const [isLoggedin, setLogIn] = useState(true);

	const value = {
		isLoggedIn: isLoggedin,
		setLogIn: setLogIn
	};

	return <AuthContext.Provider value={value}> {children} </AuthContext.Provider>;
};

export default AuthContextProvider;
