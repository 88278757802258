import InputUnstyled, { InputBaseProps } from "@mui/material/InputBase";
import { styled } from "@mui/material";
import { forwardRef } from "react";

const StyledInputElement = styled("input")`
	width: 90%;
	font-size: 2.5rem;
	font-family: IBM Plex Sans, sans-serif;
	font-weight: 400;
	line-height: 1.4375em;
	background: #e0e0e0;
	border: 1px solid #f5f5f5;
	border-radius: 30px;
	padding: 6px 20px;
	font-family: inherit;
	color: #20262d;
	transition: width 300ms ease;

	&:hover {
		background: #eaeef3;
		border-color: #e5e8ec;
	}

	&:focus {
		outline: none;
		width: 100%;
		transition: width 200ms ease-out;
	}
`;

const CustomInput = forwardRef(function CustomInput(props: InputBaseProps, ref: React.ForwardedRef<HTMLDivElement>) {
	return <InputUnstyled slots={{ input: StyledInputElement }} {...props} ref={ref} />;
	// return <InputUnstyled components={{ Input: StyledInputElement }} {...props} ref={ref} />;
});

export default CustomInput;
