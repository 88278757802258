import { FC, useCallback, useEffect, useState } from "react";
import { AnalysisFlowPropType } from "../../../../ts/propTypes/FootcareAnalysisProps.types";
import Button from "@mui/material-next/Button";
import { GeneralFootImg, RightFootGeneralImg, RightFootProfileImg } from "../../../../assets";
import { DIAGNOSIS_DATA } from "../../../../constants/diagnosis";
import "./rightFootProfile.css";
import { generateDiagnosis } from "../../../../utils/commonFunctions/diagnosisEngine";

const RightFootProfile: FC<AnalysisFlowPropType> = ({
	footcareAnalysis,
	setFootcareAnalysis,
	setCurrentIdx
}: AnalysisFlowPropType): JSX.Element => {
	const [activeDiagnosis, setActiveDiagnosis] = useState({
		text: "",
		mainPara: "",
		secondText: "",
		secondPara: "",
		diagnosis: "",
		painPoint: -1
	});
	const [knowMorePopupVisible, setKnowMorePopupVisible] = useState(false);

	const handleShowKnowMorePopup = () => setKnowMorePopupVisible(true);

	const handleCloseKnowMorePopup = () => {
		setKnowMorePopupVisible(false);
	};

	// const handlePainPointClick = useCallback(
	// 	(painPoint: number, openPopup: boolean) => {
	// 		const _activeDiagnosis = footcareAnalysis.right_diagnosis.find(
	// 			(eachRightDiagnosis: any) => eachRightDiagnosis.diagnosisPainPoint === painPoint
	// 		);
	// 		console.log(_activeDiagnosis);
	// 		setActiveDiagnosis(
	// 			Object.assign(
	// 				{},
	// 				{
	// 					text: DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].text,
	// 					mainPara:
	// 						DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].mainPara,
	// 					secondText:
	// 						DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].secondText,
	// 					secondPara:
	// 						DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].secondPara,
	// 					diagnosis: _activeDiagnosis.diagnosisName,
	// 					painPoint: _activeDiagnosis.diagnosisPainPoint
	// 				}
	// 			)
	// 		);
	// 		if (openPopup) {
	// 			handleShowKnowMorePopup();
	// 		}
	// 	},
	// 	[footcareAnalysis.right_diagnosis]
	// );

	const handlePainPointClick = (painPoint: number, openPopup: boolean) => {
		console.log("clicked", painPoint);
	};
	useEffect(() => {
		const rightDiagnosis = generateDiagnosis(footcareAnalysis, "RIGHT");
		console.log("rightDiagnosis-->", rightDiagnosis);

		setFootcareAnalysis((prevFootcareAnalysis: any) => {
			return Object.assign({}, prevFootcareAnalysis, {
				right_diagnosis: [...rightDiagnosis],
				right_diagnosis_pain_points: rightDiagnosis.map(
					(eachRightDiagnosis: any) => eachRightDiagnosis.diagnosisPainPoint
				)
			});
		});
		if (rightDiagnosis.length === 0) {
			setCurrentIdx((prevCurrentIdx: number) => prevCurrentIdx + 1);
		} else {
			const _activeDiagnosis = rightDiagnosis[0];
			setActiveDiagnosis(
				Object.assign(
					{},
					{
						text: DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].text,
						mainPara:
							DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].mainPara,
						secondText:
							DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].secondText,
						secondPara:
							DIAGNOSIS_DATA[_activeDiagnosis.diagnosisName as keyof typeof DIAGNOSIS_DATA].secondPara,
						diagnosis: _activeDiagnosis.diagnosisName,
						painPoint: _activeDiagnosis.diagnosisPainPoint
					}
				)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setFootcareAnalysis]);

	return (
		<div className="right-foot-profile  theme-cont">
			<div className="text-center gradient-header" style={{ fontSize: "3rem", zIndex: 20 }}>
				Your Right Foot Profile
			</div>
			<div className="length-mask" />
			<div className="width-mask" />
			<div className="arch-type-mask" />
			<div className="arch-type">{footcareAnalysis.right_arch_type}</div>
			<div className="theme-sub-cont">
				<div className="left-item PHQ-core-back-img-left"></div>
				<div
					className="right-item"
					style={{
						alignItems: "flex-end",
						flex: 3,
						position: "relative"
					}}
				>
					<img src={RightFootProfileImg} alt="" className="right-foot-profile-img" />
					<div
						className="know-more-popup"
						style={{
							display: knowMorePopupVisible ? "block" : "none"
						}}
					>
						<div className="what-is-text"> {`What is ${activeDiagnosis.diagnosis}?`}</div>
						<div className="main-para">{activeDiagnosis.mainPara}</div>
						<div className="second-text">{activeDiagnosis.secondText}</div>
						<div className="second-para">{activeDiagnosis.secondPara}</div>
						<div className="cross-button" onClick={handleCloseKnowMorePopup}>
							X
						</div>
					</div>
					{footcareAnalysis.right_diagnosis_pain_points.includes(3) ? (
						<div
							className={`profile-3 profile-dot ${
								activeDiagnosis.painPoint === 3 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(3, false)}
						/>
					) : null}
					{footcareAnalysis.right_diagnosis_pain_points.includes(2) ? (
						<div
							className={`profile-2 profile-dot ${
								activeDiagnosis.painPoint === 2 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(2, false)}
						/>
					) : null}
					{footcareAnalysis.right_diagnosis_pain_points.includes(1) ? (
						<div
							className={`profile-1 profile-dot ${
								activeDiagnosis.painPoint === 1 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(1, false)}
						/>
					) : null}
					{footcareAnalysis.right_diagnosis_pain_points.includes(4) ? (
						<div
							className={`profile-4 profile-dot ${
								activeDiagnosis.painPoint === 4 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(4, false)}
						/>
					) : null}
					{footcareAnalysis.right_diagnosis_pain_points.includes(7) ? (
						<div
							className={`profile-7 profile-dot ${
								activeDiagnosis.painPoint === 7 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(7, false)}
						/>
					) : null}
					{footcareAnalysis.right_diagnosis_pain_points.includes(8) ? (
						<div
							className={`profile-8 profile-dot ${
								activeDiagnosis.painPoint === 8 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(8, false)}
						/>
					) : null}
					{footcareAnalysis.right_diagnosis_pain_points.includes(6) ? (
						<div
							className={`profile-6 profile-dot ${
								activeDiagnosis.painPoint === 6 ? "pain-point-item-selected-profile" : ""
							}`}
							onClick={() => handlePainPointClick(6, false)}
						/>
					) : null}
					<div className="vertical-div" />
					<div className="diagnosis-data">
						<img className="general-foot-img" src={GeneralFootImg} />
						<div className="diagnosis-name">{activeDiagnosis.diagnosis}</div>
						<div className="diagnosis-text">{activeDiagnosis.text}</div>
						<div className="text-center">
							<Button
								sx={{
									textAlign: "center",
									fontSize: "1.2rem",
									fontFamily: "inherit",
									fontWeight: "bold",
									paddingright: "1rem",
									paddingRight: "1rem",
									background: "#60cf63",
									color: "#1f2149",
									marginRight: "0rem",
									marginTop: "1rem"
								}}
								disabled={false}
								size="large"
								variant="filledTonal"
								onClick={handleShowKnowMorePopup}
							>
								Know More
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RightFootProfile;
