import { FC, useCallback } from "react";
import { AnalysisFlowPropType } from "../../../ts/propTypes/FootcareAnalysisProps.types";
import ThemeRadioButton from "../../../components/shared/radioButton/ThemeRadioButton";
import { FIRST_PHQs } from "../../../constants/PHQ";

import { FaHandPointRight } from "react-icons/fa";

import "./generalQuestions.css";

const GeneralQuestions: FC<AnalysisFlowPropType> = ({
	footcareAnalysis,
	setFootcareAnalysis
}: AnalysisFlowPropType): JSX.Element => {
	const handleGeneralQuestionChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>, flag: boolean) => {
			const { name, value, checked } = event.target;
			setFootcareAnalysis((prevFootcareAnalysis: any) => {
				return Object.assign({}, prevFootcareAnalysis, {
					[name]: flag
				});
			});
		},
		[setFootcareAnalysis]
	);

	console.log(footcareAnalysis);

	return (
		<div className="general-questions theme-cont">
			<div className="theme-sub-cont">
				<div className="left-item" style={{ alignItems: "flex-start", marginTop: "-3.5rem" }}>
					{FIRST_PHQs.map((phq, idx) => {
						return (
							<div key={idx} className="question-container">
								<div className="question">
									<span>
										<FaHandPointRight className="question-icon" />
									</span>
									&nbsp;&nbsp;&nbsp;{phq.label}
								</div>
								<div className="radio-container">
									<ThemeRadioButton
										checked={
											footcareAnalysis[phq.name] === null ? false : footcareAnalysis[phq.name]
										}
										success
										handleChange={handleGeneralQuestionChange}
										label="Yes"
										name={phq.name}
									/>
									<ThemeRadioButton
										checked={
											footcareAnalysis[phq.name] === null ? false : !footcareAnalysis[phq.name]
										}
										success={false}
										handleChange={handleGeneralQuestionChange}
										label="No"
										name={phq.name}
									/>
								</div>
							</div>
						);
					})}
				</div>
				<div className="right-item back-img"></div>
			</div>
		</div>
	);
};

export default GeneralQuestions;
