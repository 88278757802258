import { usePromiseTracker } from "react-promise-tracker";
import { ThreeDots, Circles, Hearts, Rings, MagnifyingGlass } from "react-loader-spinner";

// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./loadingIndicator.css";
import TypewriterEffect from "../../components/TypewriterEffect";

const Loading = (): JSX.Element => {
	return (
		<div className="loader">
			<div className="text-center body-center gradient-header header" style={{ fontSize: "5rem" }}>
				Please Wait a Moment <TypewriterEffect text={"..."} delay={200} />
			</div>
		</div>
	);
};

const LoadingIndicator = (): JSX.Element => {
	const { promiseInProgress } = usePromiseTracker({ delay: 1000 });
	if (promiseInProgress) {
		return <Loading />;
	} else return <></>;
};

export default LoadingIndicator;
