// eslint-disable arrow-parens
import React, { useEffect, useState, useRef } from "react";
import { CheckCircle } from "@mui/icons-material";
import {
	Typography,
	Container,
	CssBaseline,
	Paper,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Box,
	Checkbox,
	FormControlLabel,
	Slider,
	Stack
} from "@mui/material";

const styles = {
	container: {
		display: "flex",
		alignItems: "center",
		marginTop: "16px",
		fontFamily: "Quicksand, sans-serif"
	},
	label: {
		marginRight: "16px",
		minWidth: "100px"
	},
	select: {
		flex: 1
	},
	checkIcon: {
		color: "green"
	},
	mainContainer: {
		height: "auto"
	},
	videoContainer: {
		maxHeight: "30rem",
		overflow: "hidden",
		margin: "1rem",
		border: "1px solid #ccc"
	},
	video: {
		width: "100%",
		height: "100%",
		overflow: "hidden"
	},
	fullPagePaper: {
		width: "100%", // Set to 100% for full-width
		padding: "100px", // Adjust padding as needed
		marginTop: "5px",
		display: "flex"
	},
	noSelect: {
		userSelect: "none" // Prevent text selection
	}
};

interface AvailableWebcamsProps {
	label: string;
	onChange: (id: string) => void;
}

const AvailableWebcams: React.FC<AvailableWebcamsProps> = ({ label, onChange }: any) => {
	const [webcamDevices, setWebcamDevices] = useState<string[]>([]);
	const [selectedWebcam, setSelectedWebcam] = useState<string | undefined>(undefined);

	// const [horizentalValue, setHorizentalValue] = React.useState<number[]>([0, 100]);
	// const [verticalValue, setVerticalValue] = React.useState<number[]>([0, 100]);

	const cameraCroppingKeys: { [key: string]: string } = {
		"left top": "leftTop",
		"right top": "rightTop",
		"left side": "leftSide",
		"right side": "rightSide"
	};

	const storedHorizentalValue = localStorage.getItem(cameraCroppingKeys[label.toLowerCase()] + "HorizentalValue");
	const storedVerticalValue = localStorage.getItem(cameraCroppingKeys[label.toLowerCase()] + "VerticalValue");

	const [horizentalValue, setHorizentalValue] = React.useState<number[]>(
		storedHorizentalValue ? JSON.parse(storedHorizentalValue) : [0, 100]
	);
	const [verticalValue, setVerticalValue] = React.useState<number[]>(
		storedVerticalValue ? JSON.parse(storedVerticalValue) : [0, 100]
	);

	const videoRef = useRef<HTMLVideoElement | null>(null);

	const valuetext = (value: number) => {
		return `${value}°C`;
	};

	useEffect(() => {
		const enumerateDevices = async () => {
			try {
				const devices = await navigator.mediaDevices.enumerateDevices(); // eslint-disable arrow-parens
				// eslint-disable-next-line arrow-parens
				const videoDevices = devices.filter((device) => device.kind === "videoinput"); // eslint-disable-next-line arrow-parens
				const deviceIds = videoDevices.map((device) => device.deviceId);
				setWebcamDevices(deviceIds);
			} catch (error) {
				console.error("Error enumerating devices:", error);
			} // eslint-disable-next-line arrow-parens
		};

		enumerateDevices();
	}, []);

	const handleWebcamChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
		const selectedId = event.target.value as string;
		setSelectedWebcam(selectedId);
		onChange(selectedId);

		try {
			const stream = await navigator.mediaDevices.getUserMedia({
				video: { deviceId: selectedId }
			});

			if (videoRef.current) {
				videoRef.current.srcObject = stream;
			}
		} catch (error) {
			console.error("Error accessing webcam:", error);
		}
	};

	const handleChangeHorizental = (event: Event, newValue: number | number[], activeThumb: number) => {
		if (!Array.isArray(newValue)) {
			return;
		}

		if (activeThumb === 0) {
			setHorizentalValue([Math.min(newValue[0], horizentalValue[1] - 10), horizentalValue[1]]);
		} else {
			setHorizentalValue([horizentalValue[0], Math.max(newValue[1], horizentalValue[0] + 10)]);
		}
	};

	const handleChangeVertical = (event: Event, newValue: number | number[], activeThumb: number) => {
		if (!Array.isArray(newValue)) {
			return;
		}

		if (activeThumb === 0) {
			setVerticalValue([Math.min(newValue[0], verticalValue[1] - 10), verticalValue[1]]);
		} else {
			setVerticalValue([verticalValue[0], Math.max(newValue[1], verticalValue[0] + 10)]);
		}
	};

	useEffect(() => {
		if (label.toLowerCase() === "left top") {
			localStorage.setItem("leftTopVerticalValue", JSON.stringify(verticalValue));
		}
		if (label.toLowerCase() === "right top") {
			localStorage.setItem("rightTopVerticalValue", JSON.stringify(verticalValue));
		}
		if (label.toLowerCase() === "left side") {
			localStorage.setItem("leftSideVerticalValue", JSON.stringify(verticalValue));
		}
		if (label.toLowerCase() === "right side") {
			localStorage.setItem("rightSideVerticalValue", JSON.stringify(verticalValue));
		}
	}, [verticalValue, label]);

	useEffect(() => {
		if (label.toLowerCase() === "left top") {
			localStorage.setItem("leftTopHorizentalValue", JSON.stringify(horizentalValue));
		}
		if (label.toLowerCase() === "right top") {
			localStorage.setItem("rightTopHorizentalValue", JSON.stringify(horizentalValue));
		}
		if (label.toLowerCase() === "left side") {
			localStorage.setItem("leftSideHorizentalValue", JSON.stringify(horizentalValue));
		}
		if (label.toLowerCase() === "right side") {
			localStorage.setItem("rightSideHorizentalValue", JSON.stringify(horizentalValue));
		}
	}, [horizentalValue, label]);

	console.log("horizentalValue", horizentalValue);
	console.log("verticalValue", verticalValue);

	return (
		<div>
			<div style={styles.container}>
				<Typography variant="body1" style={styles.label}>
					{label}:
				</Typography>
				<FormControl style={styles.select} variant="outlined">
					<InputLabel>{label} Webcam</InputLabel>
					<Select
						value={selectedWebcam || ""}
						onChange={handleWebcamChange as any}
						label={`${label} Webcam`}
						color="success"
					>
						<MenuItem value="">
							<em>Choose the desired camera</em>
						</MenuItem>
						{webcamDevices.map((deviceId, index) => (
							<MenuItem key={index} value={deviceId}>
								{deviceId}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{selectedWebcam && <CheckCircle style={styles.checkIcon} />}
			</div>
			{selectedWebcam && (
				<Stack sx={{ height: "auto", margin: 8 }}>
					<Stack sx={{ height: 500 }} justifyContent={"center"} direction="row">
						<Slider
							orientation="vertical"
							getAriaValueText={valuetext}
							value={verticalValue}
							sx={{ marginRight: 2, marginTop: 3 }}
							onChange={handleChangeVertical}
							valueLabelDisplay="auto"
						/>
						<div style={styles.mainContainer}>
							<Slider
								value={horizentalValue}
								valueLabelDisplay="auto"
								onChange={handleChangeHorizental}
								getAriaValueText={valuetext}
							/>
							<div style={styles.videoContainer}>
								<video ref={videoRef} style={styles.video} autoPlay playsInline />

								<div
									id="topBar"
									style={{
										position: "sticky",
										bottom: "100%",
										height: `${(100 - verticalValue[1]) * 6}px`,
										backgroundColor: "black",
										width: "100%",
										zIndex: 2
									}}
								></div>
								<div
									id="leftBar"
									style={{
										position: "sticky",
										bottom: "100%",
										height: "100rem",
										backgroundColor: "black",
										width: `${horizentalValue[0] * 6}px`,
										zIndex: 2
									}}
								></div>
								<div
									id="rightBar"
									style={{
										position: "sticky",
										bottom: "100%",
										left: "100%",
										right: 0,
										height: "100rem",
										backgroundColor: "black",
										width: `${(100 - horizentalValue[1]) * 6}px`,
										zIndex: 2
									}}
								></div>
								<div
									id="bottomBar"
									style={{
										position: "sticky",
										bottom: 0,
										height: `${verticalValue[0] * 6}px`,
										backgroundColor: "black",
										width: "100%",
										zIndex: 2
									}}
								></div>
							</div>
						</div>
					</Stack>
				</Stack>
			)}
		</div>
	);
};

interface IntegratedComponentprops {
	handleCloseSettingsDialog: () => void;
}

const IntegratedComponent = ({ handleCloseSettingsDialog }: IntegratedComponentprops) => {
	// Initialize the four tokens with empty strings
	const [tokens, setTokens] = useState({
		leftTop: "",
		rightTop: "",
		leftSide: "",
		rightSide: "",
		leftTopVerticalValue: "",
		rightTopVerticalValue: "",
		leftVerticalValue: "",
		rightVerticalValue: "",
		leftTopHorizentalValue: "",
		rightTopHorizentalValue: "",
		leftHorizentalValue: "",
		rightHorizentalValue: "",
		showCamera: false
	});

	// Function to handle storing tokens in local storage
	const handleSetTokens = () => {
		// Store tokens in local storage
		localStorage.setItem("leftTop", tokens.leftTop);
		localStorage.setItem("rightTop", tokens.rightTop);
		localStorage.setItem("leftSide", tokens.leftSide);
		localStorage.setItem("rightSide", tokens.rightSide);
		localStorage.setItem("showCamera", tokens.showCamera.toString());
		const cameraIdsChangeEvent = new Event("cameraIdsChanged");
		handleCloseSettingsDialog();
		window.dispatchEvent(cameraIdsChangeEvent);
	};

	useEffect(() => {
		setTokens({
			leftTop: localStorage.getItem("leftTop") || "",
			rightTop: localStorage.getItem("rightTop") || "",
			leftSide: localStorage.getItem("leftSide") || "",
			rightSide: localStorage.getItem("rightSide") || "",
			showCamera: localStorage.getItem("showCamera") === "true",
			leftTopVerticalValue: localStorage.getItem("leftTopVerticalValue") || "",
			rightTopVerticalValue: localStorage.getItem("rightTopVerticalValue") || "",
			leftVerticalValue: localStorage.getItem("leftVerticalValue") || "",
			rightVerticalValue: localStorage.getItem("rightVerticalValue") || "",
			leftHorizentalValue: localStorage.getItem("leftHorizentalValue") || "",
			leftTopHorizentalValue: localStorage.getItem("leftTopHorizentalValue") || "",
			rightHorizentalValue: localStorage.getItem("rightHorizentalValue") || "",
			rightTopHorizentalValue: localStorage.getItem("rightTopHorizentalValue") || ""
		});
	}, []);

	return (
		<Container maxWidth="xl">
			<CssBaseline />
			<Typography
				variant="h2"
				component="div"
				fontFamily="Quicksand, sans-serif"
				fontWeight="light"
				marginRight={"50px"}
				marginBottom={"50px"}
				marginTop={"50px"}
			>
				CAMERA
			</Typography>
			<Paper elevation={3} style={styles.fullPagePaper}>
				<div style={{ flex: 1 }}>
					<FormControlLabel
						required
						control={<Checkbox checked={tokens.showCamera} />}
						label="Show Camera"
						onChange={() => setTokens({ ...tokens, showCamera: !tokens.showCamera })}
					/>
					{/* eslint-disable arrow-parens */}
					<AvailableWebcams label="Left Top" onChange={(id) => setTokens({ ...tokens, leftTop: id })} />
					<AvailableWebcams label="Right Top" onChange={(id) => setTokens({ ...tokens, rightTop: id })} />
					<AvailableWebcams label="Left Side" onChange={(id) => setTokens({ ...tokens, leftSide: id })} />
					<AvailableWebcams label="Right Side" onChange={(id) => setTokens({ ...tokens, rightSide: id })} />
					{/* eslint-disable arrow-parens */}
				</div>
			</Paper>
			<Button
				variant="contained"
				color="success"
				onClick={handleSetTokens}
				sx={{
					margin: "2rem auto",
					display: "block",
					fontSize: "20px", // Adjust the font size as needed
					width: "200px" // Adjust the button width as needed
				}}
			>
				SAVE CAMERA
			</Button>
		</Container>
	);
};

export default IntegratedComponent;
