import { FC, useState, useEffect, useCallback } from "react";
import { AnalysisFlowPropType } from "../../../ts/propTypes/FootcareAnalysisProps.types";
import { Box, Grid, Typography } from "@mui/material";
import { CustomSlippers, D3Insole, EvaInsole, ToeBed } from "../../../assets";
import Button from "@mui/material-next/Button";
import axios, { AxiosResponse } from "axios";
import "./recommendations.css";
import { RECOMMENDATIONS, products } from "../../../constants/recommendations";
import { formatQuestionList } from "../../../utils/commonFunctions/formatQuestion";
import { crm_server_url } from "../../../config/config";
import MultiActionAreaCard from "../../../components/RecommendedProducts";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

interface product {
	product_image: string;
	product_name: string;
	product_category: string;
	product_area: string;
	description: string;
}

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary
}));

const Recommendations: FC<AnalysisFlowPropType> = ({
	footcareAnalysis,
	setFootcareAnalysis
}: AnalysisFlowPropType): JSX.Element => {
	const [leftFeetProducts, setleftFeetProducts] = useState<Array<product>>();
	const [rightFeetProducts, setrightFeetProducts] = useState<Array<product>>();

	const sendData = async () => {
		try {
			console.log(" Data is trying to send");
			const right_diagnosisName = footcareAnalysis.right_diagnosis.map(
				(diagnosis: { diagnosisName: string }) => diagnosis.diagnosisName
			);
			const left_diagnosisName = footcareAnalysis.left_diagnosis.map(
				(diagnosis: { diagnosisName: string }) => diagnosis.diagnosisName
			);
			const right_diagnosisPainPoint = footcareAnalysis.right_diagnosis.map(
				(diagnosis: { diagnosisPainPoint: number }) => diagnosis.diagnosisPainPoint
			);
			const left_diagnosisPainPoint = footcareAnalysis.left_diagnosis.map(
				(diagnosis: { diagnosisPainPoint: number }) => diagnosis.diagnosisPainPoint
			);

			const left_ques = footcareAnalysis.LEFT;
			const nonNullValues_LEFT = Object.entries(left_ques)
				.filter(([key, value]) => value !== null)
				.map(([key, value]) => ({ key, value }));

			const right_ques = footcareAnalysis.RIGHT;
			const nonNullValues_RIGHT = Object.entries(right_ques)
				.filter(([key, value]) => value !== null)
				.map(([key, value]) => ({ key, value: !!value }));

			const CrmApi = `${crm_server_url}api/v1/diagnosis/post-diagnosis`;
			const response = await axios.post(CrmApi, {
				// scan_id: footcareAnalysis.scan_id || "NULL",
				customer_scan_object_id: footcareAnalysis.scan_object_id || "NULL",
				right_feet_diagnosis_name: right_diagnosisName[0],
				right_feet_pain_point: right_diagnosisPainPoint[0],
				left_feet_diagnosis_name: left_diagnosisName[0],
				left_feet_pain_point: left_diagnosisPainPoint[0],
				right_feet_question_list: formatQuestionList(nonNullValues_RIGHT),
				left_feet_question_list: formatQuestionList(nonNullValues_LEFT),
				left_feet_arc_type: footcareAnalysis.left_arch_type,
				right_feet_arc_type: footcareAnalysis.right_arch_type
			});
		} catch (error) {
			console.error("Error sending data:", error);
		} finally {
			console.log(" Data is sent");
		}
	};

	const getRecommendedProducts = useCallback(async () => {
		try {
			const API_URL =
				"https://d2w39smwctjse1.cloudfront.net/api/v1/recommendations/get-product-recommendation-v2";

			const recommendedProductInstance: AxiosResponse = await axios.get(API_URL, {
				params: { scan_object_id: footcareAnalysis.scan_object_id /*"6606ab172c270a4156b94f98"*/ }
			});

			if (!recommendedProductInstance) throw Error();

			const { data } = recommendedProductInstance;

			console.log(data);

			const leftFeetProducts = data.result.leftFeetProducts.map((element: any) => {
				return {
					product_name: element.product_name,
					product_category: element.product_category,
					product_area: element.product_area,
					description: element.description,
					product_image: element.product_image
				};
			});

			const rightFeetProducts = data.result.rightFeetProducts.map((element: any) => {
				return {
					product_name: element.product_name,
					product_category: element.product_category,
					product_area: element.product_area,
					description: element.description,
					product_image: element.product_image
				};
			});

			setleftFeetProducts(leftFeetProducts);
			setrightFeetProducts(rightFeetProducts);
		} catch (error) {
			alert(error);
		}
	}, [sendData]);

	useEffect(() => {
		const fetchData = async () => {
			await sendData();
			setTimeout(() => {
				getRecommendedProducts();
			}, 2000);
		};
		fetchData();
		sendData().then(() => {
			getRecommendedProducts();
		});
	}, [sendData, getRecommendedProducts]);

	// useEffect(() => {
	// 	// 	//api/v1/recommendations/get-product-recommendation-v2?scan_object_id=6606ab172c270a4156b94f98
	// 	const url =
	// 		"https://d2w39smwctjse1.cloudfront.net/api/v1/recommendations/get-product-recommendation-v2?scan_object_id=6606ab172c270a4156b94f98";
	// 	axios
	// 		.get(url, {
	// 			params: {
	// 				scan_object_id: "6606ab172c270a4156b94f98" //footcareAnalysis["scan_object_id"]
	// 			}
	// 		})
	// 		.then((response) => {
	// 			setleftFeetProducts(
	// 				response.data.result.left_feet_products.map((element: any) => {
	// 					return {
	// 						product_name: element.product_name,
	// 						product_category: element.product_category,
	// 						product_area: element.product_area,
	// 						description: element.description,
	// 						product_image: element.product_image
	// 					};
	// 				})
	// 			);
	// 			setrightFeetProducts(
	// 				response.data.result.right_feet_products.map((element: any) => {
	// 					return {
	// 						product_name: element.product_name,
	// 						product_category: element.product_category,
	// 						product_area: element.product_area,
	// 						description: element.description,
	// 						product_image: element.product_image
	// 					};
	// 				})
	// 			);
	// 			console.log(rightFeetProducts);
	// 			console.log(leftFeetProducts);
	// 		}); // eslint-disable-line no-use-before-define
	// }, []);
	// const [activeProduct, setActiveProduct] = useState({
	// 	name: "",
	// 	desc: "",
	// 	price: -1
	// });
	// const [productPopupVisible, setProductPopupVisible] = useState(false);

	// const handleProductClick = (product: string) => {
	// 	setActiveProduct(
	// 		Object.assign(
	// 			{},
	// 			{
	// 				name: product,
	// 				desc: RECOMMENDATIONS[product as keyof typeof RECOMMENDATIONS].desc,
	// 				price: RECOMMENDATIONS[product as keyof typeof RECOMMENDATIONS].price
	// 			}
	// 		)
	// 	);
	// 	setProductPopupVisible(true);
	// };

	// console.log("THE SCAN ID", footcareAnalysis.scan_id);
	// console.log("PAIN POINT RIGHT", footcareAnalysis.right_diagnosis);
	// console.log("PAIN POINT LEFT", footcareAnalysis.left_diagnosis);
	// console.log("RIGHT QUESTIONS", footcareAnalysis.RIGHT);
	// console.log("LEFT QUESTIONS", footcareAnalysis.LEFT);

	// useEffect(() => {
	// 	sendData(); // Call sendData when component mounts or footcareAnalysis changes
	// }, [footcareAnalysis]);

	// const handleCloseProductPopup = () => setProductPopupVisible(false);

	// return (
	// 	<div className="recommendations theme-cont">
	// 		<div className="text-center gradient-header" style={{ fontSize: "3rem", zIndex: 20, marginBottom: "2rem" }}>
	// 			Product Recommendations Tailored To Your Feet
	// 		</div>
	// 		<Grid container>
	// 			<Grid item xl={6} lg={6} md={6} className="flex-center">
	// 				<div className="product-cont text-center text-center">
	// 					<div>
	// 						<img src={D3Insole} alt="" />
	// 					</div>
	// 					<Button
	// 						sx={{
	// 							textAlign: "center",
	// 							fontSize: "1.2rem",
	// 							fontFamily: "inherit",
	// 							fontWeight: "bold",
	// 							paddingright: "1rem",
	// 							paddingRight: "1rem",
	// 							background: "#60cf63",
	// 							color: "#1f2149",
	// 							marginTop: "1rem"
	// 						}}
	// 						disabled={false}
	// 						size="large"
	// 						onClick={() => handleProductClick("3D Insole")}
	// 						variant="filledTonal"
	// 					>
	// 						Know More
	// 					</Button>
	// 				</div>
	// 			</Grid>
	// 			<Grid item xl={6} lg={6} md={6} className="flex-center">
	// 				<div className="product-cont text-center">
	// 					<div>
	// 						<img src={EvaInsole} alt="" />
	// 					</div>
	// 					<Button
	// 						sx={{
	// 							textAlign: "center",
	// 							fontSize: "1.2rem",
	// 							fontFamily: "inherit",
	// 							fontWeight: "bold",
	// 							paddingright: "1rem",
	// 							paddingRight: "1rem",
	// 							background: "#60cf63",
	// 							color: "#1f2149",
	// 							marginTop: "1rem"
	// 						}}
	// 						disabled={false}
	// 						size="large"
	// 						onClick={() => handleProductClick("Eva Insole")}
	// 						variant="filledTonal"
	// 					>
	// 						Know More
	// 					</Button>
	// 				</div>
	// 			</Grid>
	// 		</Grid>
	// 		<Grid container style={{ marginTop: "8rem" }}>
	// 			<Grid item xl={6} lg={6} md={6} className="flex-center">
	// 				{/* <div className="product-cont text-center">
	// 					<div>
	// 						<img src={ToeBed} alt="" />
	// 					</div>
	// 					<Button
	// 						sx={{
	// 							textAlign: "center",
	// 							fontSize: "1.2rem",
	// 							fontFamily: "inherit",
	// 							fontWeight: "bold",
	// 							paddingright: "1rem",
	// 							paddingRight: "1rem",
	// 							background: "#60cf63",
	// 							color: "#1f2149",
	// 							marginTop: "1rem"
	// 						}}
	// 						disabled={false}
	// 						size="large"
	// 						variant="filledTonal"
	// 						onClick={() => handleProductClick("Toe Bed")}
	// 					>
	// 						Know More
	// 					</Button>
	// 				</div> */}
	// 			</Grid>
	// 			<Grid item xl={6} lg={6} md={6} className="flex-center">
	// 				{/* <div className="product-cont text-center">
	// 					<div>
	// 						<img src={CustomSlippers} alt="" />
	// 					</div>
	// 					<Button
	// 						sx={{
	// 							textAlign: "center",
	// 							fontSize: "1.2rem",
	// 							fontFamily: "inherit",
	// 							fontWeight: "bold",
	// 							paddingright: "1rem",
	// 							paddingRight: "1rem",
	// 							background: "#60cf63",
	// 							color: "#1f2149",
	// 							marginTop: "1rem"
	// 						}}
	// 						disabled={false}
	// 						size="large"
	// 						onClick={() => handleProductClick("Custom Slippers")}
	// 						variant="filledTonal"
	// 					>
	// 						Know More
	// 					</Button>
	// 				</div> */}
	// 			</Grid>
	// 		</Grid>
	// 		<div
	// 			className="product-details-cont"
	// 			style={{
	// 				display: productPopupVisible ? "block" : "none"
	// 			}}
	// 		>
	// 			<div className="product-name">{activeProduct.name}</div>
	// 			<div className="product-details">{activeProduct.desc}</div>
	// 			{/* <div className="product-price">Rs {activeProduct.price}</div> */}
	// 			<div className="cross-button" onClick={handleCloseProductPopup}>
	// 				X
	// 			</div>
	// 		</div>
	// 	</div>
	// );
	if (!rightFeetProducts) return <></>;
	if (!leftFeetProducts) return <></>;

	return (
		<Box sx={{ overflowY: "scroll", height: "100vh" }}>
			<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "20px" }}>
				<Typography variant="h3" component="div" color="#5cb85c" fontFamily={"Quicksand"} fontWeight={"bold"}>
					Recommended Products
				</Typography>
			</Box>
			<Typography
				variant="h4"
				component="div"
				color="#5cb85c"
				fontFamily={"Quicksand"}
				fontWeight={"bold"}
				sx={{ padding: "0 0 0 2%" }}
			>
				For Your Left Feet
			</Typography>
			<Box className="product_card" sx={{ paddingBottom: "5%" }}>
				{leftFeetProducts.map((product, index) => {
					return <MultiActionAreaCard key={index} productDetails={product} />;
				})}
			</Box>
			<Typography
				variant="h4"
				component="div"
				color="#5cb85c"
				fontFamily={"Quicksand"}
				fontWeight={"bold"}
				sx={{ padding: "0 0 0 2%" }}
			>
				For Your Right Feet
			</Typography>
			<Box className="product_card">
				{rightFeetProducts.map((product, index) => {
					return <MultiActionAreaCard key={index} productDetails={product} />;
				})}
			</Box>
		</Box>
	);
};

export default Recommendations;
