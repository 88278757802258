import { FC, useCallback, useEffect, useState } from "react";
import { AnalysisFlowPropType } from "../../../ts/propTypes/FootcareAnalysisProps.types";

import "./scanReport.css";
import QRCode from "react-qr-code";

const ScanReport: FC<AnalysisFlowPropType> = ({ footcareAnalysis }: AnalysisFlowPropType): JSX.Element => {
	const [leftEU, setLeftEU] = useState<number | null>(null);
	const [rightEU, setRightEU] = useState<number | null>(null);

	const getEUShoeSize = useCallback((shoeSize: number) => {
		switch (shoeSize) {
			case 4: {
				return 37;
			}
			case 5: {
				return 38;
			}
			case 5.5: {
				return 39;
			}
			case 6: {
				return 40;
			}
			case 6.5: {
				return 40;
			}
			case 7: {
				return 41;
			}
			case 7.5: {
				return 41;
			}
			case 8: {
				return 42;
			}
			case 9: {
				return 43;
			}
			case 9.5: {
				return 44;
			}
			case 10: {
				return 45;
			}
			case 10.5: {
				return 45;
			}
			case 11: {
				return 46;
			}
			case 12: {
				return 46;
			}
			case 13: {
				return 47;
			}
			case 14: {
				return 48;
			}
			case 15: {
				return 49;
			}
			case 16: {
				return 50;
			}
			default:
				return 37;
		}
	}, []);

	const parsetoInt = (len: number) => {
		const intLen = Math.floor(len);
		return intLen;
	};

	useEffect(() => {
		setLeftEU(getEUShoeSize(footcareAnalysis.left_shoe_size));
		setRightEU(getEUShoeSize(footcareAnalysis.right_shoe_size));
	}, [footcareAnalysis.left_shoe_size, footcareAnalysis.right_shoe_size, getEUShoeSize]);

	return (
		<div className="scan-report theme-cont">
			<div className="scan-image"></div>
			<div className="basic-details">
				<div className="name">{footcareAnalysis.name}</div>
				<div className="gender">{footcareAnalysis.gender[0]}</div>
				<div className="age">
					{footcareAnalysis.minimum_age} - {footcareAnalysis.maximum_age} yrs
				</div>
			</div>
			<div className="basic-report">Basic Foot Analysis Report</div>
			<div className="left-heel-width">Heel Width: {parsetoInt(footcareAnalysis.left_width * (67 / 100))} mm</div>
			<div className="right-heel-width">
				Heel Width: {parsetoInt(footcareAnalysis.right_width * (67 / 100))} mm
			</div>
			<div className="left-length">
				<div className="orientation">{parsetoInt(footcareAnalysis.left_length)} mm</div>
			</div>
			<div className="left-width">
				<div className="">{footcareAnalysis.left_width} mm</div>
			</div>
			<div className="left-toe-type">
				<div className="">{footcareAnalysis.left_width_type}</div>
			</div>
			<div className="right-width">
				<div className="">{footcareAnalysis.right_width} mm</div>
			</div>
			<div className="right-toe-type">
				<div className="">{footcareAnalysis.right_width_type}</div>
			</div>
			<div className="right-length">
				<div className="orientation">{parsetoInt(footcareAnalysis.right_length)} mm</div>
			</div>
			<div className="right-shoe-size">
				<div className="">{`${footcareAnalysis.right_shoe_size} (EU ${footcareAnalysis.right_shoe_size_eu})`}</div>
			</div>
			<div className="left-shoe-size">
				<div className="">{`${footcareAnalysis.left_shoe_size} (EU ${footcareAnalysis.left_shoe_size_eu})`}</div>
			</div>
			<div className="left-arch-type">
				<div>
					{["LOW", "FLAT", "Low", "High", "HIGH", "Flat"].includes(footcareAnalysis.left_arch_type)
						? `${footcareAnalysis.left_arch_type} Arch`
						: footcareAnalysis.left_arch_type}
				</div>
			</div>
			<div className="right-arch-type">
				<div>
					{["LOW", "FLAT", "Low", "High", "HIGH", "Flat"].includes(footcareAnalysis.right_arch_type)
						? `${footcareAnalysis.right_arch_type} Arch`
						: footcareAnalysis.right_arch_type}
				</div>
			</div>
			<div className="left-instep-height">
				<div>{footcareAnalysis.left_instep_height} mm</div>
			</div>
			<div className="right-instep-height">
				<div>{footcareAnalysis.right_instep_height} mm</div>
			</div>
			<div className="left-arch-height">
				<div>{footcareAnalysis.left_arch_height} mm</div>
			</div>
			<div className="right-arch-height">
				<div>{footcareAnalysis.right_arch_height} mm</div>
			</div>
			<div className="right-bottom-qr">
				<QRCode
					value={footcareAnalysis.link || "https://www.bata.com"}
					size={256}
					style={{ height: "auto", maxWidth: "10rem" }}
				/>
			</div>
			{/* <div style={{ zIndex: 4000 }}>
				
			</div> */}
		</div>
	);
};

export default ScanReport;
