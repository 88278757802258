import { useNavigate } from "react-router-dom";
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function NotLoggedInCard() {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate("/login");
	};

	return (
		<Box sx={{ width: "100%", height: "100%" }}>
			<Card variant="outlined">
				<React.Fragment>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column"
						}}
					>
						<CardContent>
							<Typography variant="h2">User Not Logged In </Typography>
						</CardContent>
						<CardActions>
							<Button variant="contained" size="small" color="success" onClick={handleClick}>
								Log In
							</Button>
						</CardActions>
					</Box>
				</React.Fragment>
			</Card>
		</Box>
	);
}
