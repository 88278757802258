import { FC, useCallback, useEffect, useRef, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./personalInformation.css";
import { PersonalInformationPropTypes } from "../../../ts/propTypes/FootcareAnalysisProps.types";
import { FaMale, FaFemale } from "react-icons/fa";
import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { AgeRange } from "../../../ts/footcare.types";

const theme = createTheme({
	typography: {
		allVariants: {
			fontFamily: "Quicksand",
			textTransform: "none",
			fontWeight: "bold",
			fontSize: 35
		}
	}
});

const shoeSizes: number[] = [];
const ageRanges: AgeRange[] = [
	{ minimumAge: 1, maximumAge: 2 },
	{ minimumAge: 3, maximumAge: 5 },
	{ minimumAge: 6, maximumAge: 7 },
	{ minimumAge: 8, maximumAge: 10 },
	{ minimumAge: 11, maximumAge: 14 },
	{ minimumAge: 15, maximumAge: 17 },
	{ minimumAge: 18, maximumAge: 24 },
	{ minimumAge: 25, maximumAge: 34 },
	{ minimumAge: 35, maximumAge: 44 },
	{ minimumAge: 45, maximumAge: 54 },
	{ minimumAge: 55, maximumAge: 64 },
	{ minimumAge: 65, maximumAge: 999 }
];

for (let idx = 1; idx <= 12; idx++) {
	shoeSizes.push(idx);
}

const PersonalInformation: FC<PersonalInformationPropTypes> = ({
	footcareAnalysis,
	setFootcareAnalysis
}: PersonalInformationPropTypes): JSX.Element => {
	const timeoutRef = useRef<any>(null);

	const startTimeout = (value: any) => {
		timeoutRef.current = setTimeout(() => {
			if (value != "")
				setFootcareAnalysis((prevFootcareAnalysis: any) => {
					return Object.assign({}, prevFootcareAnalysis, {
						weight: Number(value)
					});
				});
		}, 5000);
	};

	const handleGenderChange = useCallback(
		(gender: "MALE" | "FEMALE") => {
			console.log(gender);
			setFootcareAnalysis((prevFootcareAnalysis: any) => {
				return Object.assign({}, prevFootcareAnalysis, {
					gender
				});
			});
		},
		[setFootcareAnalysis]
	);

	const handleAgeRangeChange = useCallback(
		(ageRange: AgeRange) => {
			const { maximumAge, minimumAge } = ageRange;
			setFootcareAnalysis((prevFootcareAnalysis: any) => {
				return Object.assign({}, prevFootcareAnalysis, {
					minimum_age: minimumAge,
					maximum_age: maximumAge
				});
			});
		},
		[setFootcareAnalysis]
	);

	const handleShoeSizeChange = useCallback(
		(shoeSize: number) => {
			setFootcareAnalysis((prevFootcareAnalysis: any) => {
				return Object.assign({}, prevFootcareAnalysis, {
					shoe_size: shoeSize
				});
			});
		},
		[setFootcareAnalysis]
	);

	const [inputValue, setInputValue] = useState("");

	const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const newInputValue = event.target.value;
		clearTimeout(timeoutRef.current);
		setInputValue(newInputValue);
	}, []);

	useEffect(() => {
		startTimeout(inputValue);
	}, [inputValue]);

	console.log("Footcare", footcareAnalysis);

	return (
		<div className="personal-information">
			<div className="gender-back-img" />
			<div className="gradient-header text-center">Please Stand Still While We Process Your Feet</div>
			<div className="personal-information-sub">
				<div className="left-item">
					<h1 className="sub-header text-center">Choose Your Gender</h1>
					<div className="gender-container mgb-3rem">
						<div className="gender-subcontainer">
							<IconButton onClick={() => handleGenderChange("MALE")}>
								<FaMale
									className="gender-icon"
									style={{
										color: footcareAnalysis?.gender === "MALE" ? "#409F61" : "inherit"
									}}
								/>
							</IconButton>
							<div className="gender-label">Male</div>
						</div>
						<div className="gender-subcontainer">
							<IconButton onClick={() => handleGenderChange("FEMALE")}>
								<FaFemale
									className="gender-icon"
									style={{
										color: footcareAnalysis?.gender === "FEMALE" ? "#409F61" : "inherit"
									}}
								/>
							</IconButton>
							<div className="gender-label">Female</div>
						</div>
					</div>
					<h1 className="sub-header text-center">Choose Your Shoe Size</h1>
					<div>
						{shoeSizes.map((shoeSize, idx) => {
							return (
								<>
									<Button
										className={
											shoeSize === footcareAnalysis.shoe_size
												? "shoe-size-button active"
												: "shoe-size-button"
										}
										onClick={() => handleShoeSizeChange(shoeSize)}
									>
										{shoeSize}
									</Button>
									{[4, 8, 12].includes(shoeSize) ? <div /> : null}
								</>
							);
						})}
					</div>
				</div>
				<div className="right-item">
					<div className="right-item-subcontainer">
						<h1 className="sub-header text-center">Choose Your Age</h1>
						<Grid container rowGap={6} columnGap={0} alignItems={"flex-end"} justifyContent={"flex-end"}>
							{ageRanges.map((ageRange, idx) => {
								return (
									<Grid
										className="text-center-flex"
										item
										xl={6}
										lg={6}
										md={6}
										sm={6}
										xs={6}
										key={idx}
									>
										<Button
											className={
												ageRange.minimumAge === footcareAnalysis.minimum_age &&
												ageRange.maximumAge === footcareAnalysis.maximum_age
													? "age-range-button active"
													: "age-range-button button-theme-color"
											}
											onClick={() => handleAgeRangeChange(ageRange)}
										>
											{ageRange.maximumAge !== 999
												? `${ageRange.minimumAge} to ${ageRange.maximumAge} yrs`
												: `${ageRange.minimumAge} yrs Above`}
										</Button>
									</Grid>
								);
							})}
						</Grid>
					</div>
				</div>
			</div>
			<div>
				<ThemeProvider theme={theme}>
					<Typography variant="h4">Enter your Weight</Typography>
				</ThemeProvider>
			</div>
			<div className="weight-input">
				<div>
					<br />
					<TextField
						className="weight"
						variant="outlined"
						size="medium"
						inputProps={{ style: { fontSize: 30, fontWeight: "bold" } }}
						sx={{
							width: "300px",
							height: "80px",
							input: { color: "#14A44D" }
						}}
						onChange={handleChange}
					/>
				</div>
				<div style={{ paddingLeft: "10px" }}>
					<br />
					<br />
					<ThemeProvider theme={theme}>
						<Typography variant="h5">kg</Typography>
					</ThemeProvider>
				</div>
			</div>
		</div>
	);
};

export default PersonalInformation;
