import { useCallback, useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios, { AxiosError, AxiosResponse } from "axios";
import { FullHeightBox, LoginCard, CustomKioskLogin, StyledTextField } from "../components/LoginPage";
import AuthContext from "../context/AuthContext/AuthContext";
import { crm_server_url } from "../config/config";

export default function TwoBoxPage() {
	const navigate = useNavigate();
	const [credentials, setCredentials] = useState({
		device_id: null
	});
	const { isLoggedIn, setLogIn } = useContext(AuthContext);
	const valueRef = useRef<HTMLInputElement | null>(null);
	const [inputError, setInputError] = useState(false);
	const [hardwareID, setHardwareID] = useState<string>("");
	const [storeNumber, setStoreNumber] = useState(0);
	const [storeName, setStoreName] = useState("");
	const [groupNumber, setGroupNumber] = useState(0);
	const [groupName, setGroupName] = useState("");

	const handleCredentialChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { value, name } = event.target;
			setCredentials(
				Object.assign({}, credentials, {
					[name]: value
				})
			);
		},
		[credentials]
	);

	const saveInLocalStorage = useCallback(
		(response: AxiosResponse<any, any>, setValue: React.Dispatch<React.SetStateAction<any>>, value: string) => {
			let dir = "";
			if (value.includes("group")) {
				dir = "group-details";
			} else {
				dir = "store-details";
			}
			if (response && response.data.result[0]) {
				console.log("value from the response", response.data.result[0][dir][value]);
				setValue(response.data.result[0][dir][value]);
				localStorage.setItem(value, response.data.result[0][dir][value].toString());
			}
		},
		[]
	);

	const handleErrors = (error: any) => {
		if (axios.isAxiosError(error)) {
			const axiosError = error as AxiosError;

			if (axiosError.response) {
				// The request was made and the server responded with a status code
				const status = axiosError.response.status;

				if (status === 404) {
					alert("Resource not found. Please check the URL.");
				} else {
					alert(`Server returned an error with status: ${status}`);
				}
			} else if (axiosError.request) {
				// The request was made but no response was received
				alert("No response from the server. Please try again later.");
			} else {
				// Something happened in setting up the request
				alert("Error in sending the request. Please check your network connection.");
			}
		} else {
			// Non-Axios error
			alert("An unexpected error occurred. Please try again.");
		}
	};

	const handleLoginSubmitClick = useCallback(async () => {
		try {
			const machineID = valueRef.current?.value ?? null;
			if (!machineID) {
				setInputError(true);
				return;
			}
			const apiUrl_login = `${crm_server_url}api/v1/devices/get-device/`;
			setInputError(false);
			const response = await axios.get(apiUrl_login, {
				params: {
					device_id: machineID
				}
			});
			if (response.data.result.length === 0) {
				return;
			}
			console.log(response);
			saveInLocalStorage(response, setStoreNumber, "store_number");
			saveInLocalStorage(response, setStoreName, "store_name");
			saveInLocalStorage(response, setGroupNumber, "group_number");
			saveInLocalStorage(response, setGroupName, "group_name");
			localStorage.setItem("machine_id", machineID);

			if (response) await localStorage.setItem("loginStateForUser", "true");

			navigate("/start");
			setLogIn(true);
		} catch (err) {
			console.log(err);
			handleErrors(err);
		}
	}, [credentials]);

	return (
		<Box sx={{ display: "flex", height: "100vh" }}>
			<FullHeightBox sx={{ width: "auto", maxWidth: "50%" }}>
				<img src="bg_page.png" alt="logo" style={{ maxWidth: "100%", maxHeight: "100%", width: "auto" }} />
			</FullHeightBox>
			<div></div>
			<FullHeightBox sx={{ width: "auto", maxWidth: "50%" }}>
				<LoginCard>
					<CardContent
						sx={{
							width: "auto",
							maxWidth: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center"
						}}
					>
						<CustomKioskLogin variant="h1">KIOSK LOGIN</CustomKioskLogin>
						<img
							src="logo_curafoot.png"
							style={{
								marginTop: "50px",
								maxWidth: "80%",
								width: "auto"
							}}
							alt="Logo"
						/>

						<TextField
							required={true}
							id="machineID"
							sx={{
								padding: 4,
								borderColor: inputError ? "red" : undefined
							}}
							placeholder="Enter the Machine ID"
							inputRef={valueRef}
							error={inputError}
							helperText={inputError ? "Machine ID is required" : undefined}
						/>
					</CardContent>

					<Button
						variant="contained"
						sx={{ width: "400px", alignContent: "center", height: "60px" }}
						color="success"
						onClick={handleLoginSubmitClick}
					>
						LOG IN
					</Button>
				</LoginCard>
			</FullHeightBox>
		</Box>
	);
}
