const ImageScan = () => {
	return (
		<>
			<div className="text-center body-center gradient-header header" style={{ fontSize: "5rem" }}>
				<h1>Your Feet Are Getting Analysed, Please Don&apos;t Remove Your Feet</h1>
			</div>
		</>
	);
};

export default ImageScan;
