import React from "react";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";

interface LightSliderProps {
	label: string;
	value: number;
	onChange: (newValue: number) => void;
}

const theme = createTheme({
	palette: {
		primary: {
			main: "#2196F3" // Your primary color
		},
		secondary: {
			main: "#FFC107" // Your secondary color (yellow in your example)
		}
	}
});

const ImageSlider: React.FC<LightSliderProps> = ({ label, value, onChange }) => {
	const handleChange = (event: Event, newValue: number | number[]) => {
		onChange(newValue as number);
	};

	return (
		<div>
			<Typography id="light-slider" gutterBottom>
				{label}: {value}
			</Typography>
			<Slider
				value={value}
				onChange={handleChange}
				aria-labelledby="light-slider"
				min={0}
				max={500}
				valueLabelDisplay="auto"
				color="primary"
			/>
		</div>
	);
};

export default ImageSlider;
